import { theme } from "@chakra-ui/core";
import palx from 'palx'

import isArray from 'lodash/isArray';
import range from 'lodash/range';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';

import memorize from '../../utils/memorize'

const toEmPx = n => `${n * 16}px`
export const breakpoints = [30, 48, 62, 80, 90].map(toEmPx);
export const containerWidth = [
  26,
  44,
  60,
].map(toEmPx);

export const responsiveIndex = [
  [2, 'mobile'],
  [3, 'tablet'],
  [4, 'desktop'],
]

const responsiveMap = breakpoints.map((_, i) => {
  const id = responsiveIndex.findIndex(([ri]) => ri > i)
  return id >= 0 ? id : responsiveIndex.length
})

export const responsive = memorize((...args) => {
  const argsLen = args.length
  if (argsLen <= 1) return args[0]
  return breakpoints.map((_, i) => get(args, [responsiveMap[i]], null))
});

export const mobileOrDesktop = responsive

const font = 'Roboto, "Noto Sans TC", "PingFang TC", "HeiTi TC", "Microsoft JhengHei", sans-serif';
const fonts = {
  heading: font,
  notoSerif: '"Noto Serif TC", serif',
  PingFang: 'Roboto, "PingFang TC"',
  body: font,
  mono: "Menlo, monospace",
}

const key = [50].concat(range(100, 1000, 100))
const colors = mapValues(palx('#ffeb00'), colors => {
  if (isArray(colors)) {
    return colors.reduce((all, c, i) => {
      all[key[i]] = c
      return all
    }, {})
  }
  return colors
})

const primary = 'yellow'
const secondary = 'green'
const danger = 'red'
const gray = '#808080'
const bgYellow = '#FFEB00'
const lightGray = '#B3B3B3'
const bgGray = '#E8E8E8'
const textGray = '#A8A8A8'

export default {
  ...theme,
  fonts,
  colors: {
    bgYellow,
    lightGray,
    textGray,
    bgGray,
    ...theme.colors,
    ...colors,
    black: '#000',
    white: '#fff',
    primary: get(colors, `${primary}.200`),
    progress: {
      500: get(colors, `${primary}.200`),
    },
    clock: {
      500: bgYellow,
    },
    secondary: get(colors, `${secondary}.200`),
    danger: get(colors, `${danger}.200`),
    text: get(colors, 'black'),
    custom: {
      gray,
    },
  },
  breakpoints,
  containerWidth,
  headerHeight: '3em',
};
