import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import WebFont from 'webfontloader'
import ReactGA from 'react-ga';
import smoothscroll from 'smoothscroll-polyfill';
import { BrowserRouter } from 'react-router-dom';
import 'intersection-observer'; // optional polyfill


import history from './utils/history';
import creatStore from './stores/createStore';
import ThemeProvider from './components/ThemeProvider'
import { FirebaseProvider } from './services/firebase/index'

import MediaProvider from './contexts/mediaQuery/MediaProvider'
import QSProvider from './contexts/qs/Provider'
import UserProvider from './contexts/user/Provider'
import DataProvider from './contexts/data/DataProvider';
import DonateProvider from './contexts/donate/Provider';
import LargeFontProvider from './contexts/largeFont/Provider';
// import Layout from './containers/Layout'

import App from './App';

const initialState = {};
const store = creatStore(initialState, history);
ReactGA.initialize('UA-77055530-29');
smoothscroll.polyfill();

WebFont.load({
  google: {
    families: [
      'Noto+Sans+TC:400,500,700,900',
      // 'Noto+Serif+TC:wght@400,500,700',
      'Roboto:400,700',
    ],
  },
});

ReactDOM.render(
  <Provider store={store}>
    <FirebaseProvider dispatch={store.dispatch}>
      <LargeFontProvider>
        <ThemeProvider>
          <QSProvider>
            <UserProvider>
              <MediaProvider>
                <BrowserRouter>
                  <DataProvider>
                    <DonateProvider>
                      <App />
                    </DonateProvider>
                  </DataProvider>
                </BrowserRouter>
              </MediaProvider>
            </UserProvider>
          </QSProvider>
        </ThemeProvider>
      </LargeFontProvider>
    </FirebaseProvider>
  </Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
