import React from 'react';
import { Progress } from "@chakra-ui/core";

import getAnswerStats from '../QuestionPage/getAnswerStats'
import Box from '../../components/Box'

const StageProgress = () => {
  const { clearCount } = getAnswerStats()
  return (
    <>
      <Box mb="4" />
      <Box.Fixed top="3em" left="0" right="0">
        <Progress value={Math.min((clearCount + 1) * 10, 100)} height="24px" color="progress" />
      </Box.Fixed>
    </>
  );
};

export default StageProgress;
