import React from 'react'
import { Redirect } from 'react-router-dom'

import Container from '../../components/Container'
import Box from '../../components/Box'
import Link from '../../components/Link'
import Image from '../../components/Image'
import Text from '../../components/Text'
import BottomSnapButton from '../../components/BottomSnapButton'
import { responsive } from '../../components/ThemeProvider/theme';

import StageProgress from './StageProgress'
import { stageByKey } from './data'
import halflogo from './half.png'
import button from './button.png'
import getAnswerStats from '../QuestionPage/getAnswerStats'
import useResponsive from '../../contexts/mediaQuery/useResponsive'

const StageClear = ({ match: { params: { key } } }) => {
  const stage = stageByKey[key]
  const { allClear, half } = getAnswerStats()
  const { isMobile } = useResponsive()
  if (!key) {
    return <Redirect to="/stage" />
  }
  return (
    <Container.Fullpage withHeader>
      <StageProgress />
      {half ? (
        <Box
          maxWidth="35em"
          mx="auto"
          px="0.5em"
          pt={responsive(0, '6em')}
        >
          <Box
            position={responsive('absolute', 'relative')}
            top={responsive('45%', 0)}
            left={responsive('50%', 0)}
            width="100%"
            transform={responsive('translate(-50%, -50%)', 'none')}
            mb="5.25rem"
          >
            <Image src={halflogo} />
          </Box>
          <BottomSnapButton to="/stage">
            繼續作答
          </BottomSnapButton>
        </Box>
      ) : (
        <Box as={!isMobile && Box.AbsCenter} width="full" pt={responsive(12, 0)}>
          {/* <Heading as="h4" fontSize="1.25em" my="2">台灣安全嗎？</Heading> */}
          <Image px={responsive('20%', '40%')} pb="6" src={stage.src} />
          {/* <Box>
            <Heading textAlign="center">
              {allClear ? '全' : '恭喜'}通關！
            </Heading>
          </Box> */}
          <Box my="4">
            <Text textAlign="center" fontWeight="bold" fontSize="1.625em">{allClear ? '全' : stage.name}領域的作答紀錄<br/>已儲存</Text>
            {/* <Text textAlign="center" fontSize="2em" fontWeight="500">前往{allClear ? '領取專屬報告' : '挑戰下一個領域'}</Text> */}
          </Box>
          <BottomSnapButton to={allClear ? '/info' : '/stage'}>
            {allClear ? '領取報告' : '挑戰下一個領域'}
          </BottomSnapButton>
        </Box>
      )}
      {/* <Modal isCentered isOpen={isOpen} onClose={onClose} >
        <ModalOverlay />
        <ModalContent bg="transparent" maxWidth="19.375rem">
          <Board position="relative" bg="white" title="50題成就達成" mb="0">
            <Text.Bold fontSize="1rem">你完成一半的大考驗了</Text.Bold>
            <Text fontSize="0.75rem">繼續完成測驗，你可以獲得</Text>
            <Box mt="1.5rem" fontSize="0.875rem" >
              <Box borderLeft="4px solid" fontWeight="bold" borderColor="bgYellow" px="0.5rem">
                個人化認知分析報告
              </Box>
              <Text my="0.875rem">＆</Text>
              <Box borderLeft="4px solid" borderColor="bgYellow" px="0.5rem">
                <Flex alignItems="center" flexWrap="wrap">
                  {logos.map((d, k) => (
                    <Box width={d.width} mx="0.5rem" key={k}>
                      <Image src={d.src} />
                    </Box>
                  ))}
                </Flex>
                <Text fontWeight="bold">根據結果為你親選的專屬內容</Text>
              </Box>
            </Box>
            <BottomSnapButton position="relative" mt="-0.625em" square to="/stage">繼續挑戰</BottomSnapButton>
            <ModalCloseButton color="white" />
          </Board>
        </ModalContent>
      </Modal> */}
    </Container.Fullpage>
  )
}

export default StageClear
