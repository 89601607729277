import React, { useContext } from 'react'
import { useHistory } from 'react-router';
import { useHover } from 'react-use';
import { keyframes } from '@emotion/core';

import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Link from '../../../components/Link'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import theme from '../../../components/ThemeProvider/theme'
import donateContext from '../../../contexts/donate/context';

import { Board } from '../../Introduction'
import datafriend from '../Support/datafriend.png'
import { useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import icon from './icon.png'
import action from './action.png'
import styled from '@emotion/styled';

const desc = [
  '製作1份重要事實測驗',
  '改造3份重要公共議題資訊',
  '設計1組數據素養教材'
]

const regexText = /([^\d]+)([\d][\W])([\W]+)/

const products = [
  {
    desc: '贊助我們600元，\n讓我們多讀1份重要報告！',
    gain: '你將獲得「數據好朋友」1份',
    img: datafriend,
    price: 600,
    content: [
      '數據探究流程圖海報 1張',
      '數據實用技巧攻略 1份',
      '數據使用Checklist 1份'
    ],
    id: 18032,
  }
]

const anime = keyframes`
  0%,
  100% {
    border-color: black;
  }

  33%,
  67% {
    border-color: ${theme.colors.bgYellow}};
  }
`
const BorderAnimate = styled(Box.Absolute)`
  animation: ${anime} 1s infinite;
  border: 1px solid;
`

const ProductCard = ({ data, hovered, children }) => {
  const history = useHistory()
  const donationCount = useSelector(state => state.firebase.data.donationCount?.[data.id])
  return (
    <Board
      title="贊助方案與特選回饋品"
      mt="1.25em"
      onClick={() => history.push(`/checkout/${data.id}`)}
      cursor="pointer"
      border={hovered ? '1px solid transparent' : '1px solid'}
      position="relative"
    >
      <Text.Bold whiteSpace="pre-wrap">
        {data.desc}
      </Text.Bold>
      <Box
        mt="0.75rem"
        borderLeft="4px solid"
        borderColor="bgYellow"
        pl="0.375rem"
      >{data.gain}</Box>
      <Box my="0.875em">
        <Image src={data.img} />
      </Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Text.Bold fontSize="1.25em">NTD. {data.price}</Text.Bold>
        <Box fontSize="0.75em" p="0.0625em 0.25rem" bg="bgYellow" border="1px solid">
          已被贊助 {donationCount} 次
        </Box>
      </Flex>
      <Box mt="1rem" fontSize="1rem">內含：</Box>
      <Box as="ul" listStyleType="circle" mb="1em">
        {data.content.map((t, k) => (
          <Box as="li" ml="1rem" mt="0.25rem" fontSize="0.875rem" key={k} >
            {t}
          </Box>
        ))}
      </Box>
      {children}
      <Text fontSize="0.875rem">＊贊助回饋品將於5-7個工作天內出貨</Text>
    </Board>
  )
}

const Action = (props) => {
  useFirebaseConnect(['donationCount'])
  const { money, donate } = useContext(donateContext)
  const history = useHistory()
  const element = (hovered) => <Box.Absolute as={hovered && BorderAnimate} borderRadius="0.5em" top="-1px" bottom="-1px" left="-1px" right="-1px" />
  const [hoverable, hovered] = useHover(element)
  return (
    <Box {...props}>
      <Box mb="6" width="8.625em" mx="auto">
        <Image src={action} />
      </Box>
      <Text.Bold fontSize="1.25em" textAlign="center">支持我們 讓計畫走得更遠</Text.Bold>
      <Box mt="4" borderTop="1px solid">
        <Text mt="4" fontSize="1.125em" textAlign="center">2021下半年，我們預計製作</Text>
        <Box textAlign="center">
          <Box.Inline>
            {desc.map((text, i) => (
              <Flex alignItems="center" mt="0.75em" key={i}>
                <Box width="1.875em">
                  <Image src={icon} />
                </Box>
                <Flex ml="0.75rem" alignItems="center">
                  <Text>{regexText.exec(text)[1]}</Text>
                  <Text.Bold mx="0.125em" fontSize="1.125rem">{regexText.exec(text)[2]}</Text.Bold>
                  <Text>{regexText.exec(text)[3]}</Text>
                </Flex>
              </Flex>
            ))}
          </Box.Inline>
        </Box>
      </Box>
      <Box mt="1em" textAlign="center">
        <Button
          href={`https://redesigninfo.cc/2021%e4%b8%8b%e5%8d%8a%e5%b9%b4%e5%b7%a5%e4%bd%9c%e8%a6%8f%e5%8a%83/?utm_source=exam&utm_medium=support&utm_campaign=supportus`}
        >
            完整了解下半年計畫
      </Button>
      </Box>
      <Board position="relative" title="自由贊助" mt="2.25em">
        <Text.Bold fontSize="1em">贊助我們一杯咖啡，讓我們能做更多</Text.Bold>
        <Text fontSize="0.75em">可自行輸入金額</Text>
        <Flex
          alignItems="center"
          borderRadius="0.5em"
          border="1px
          solid"
          mt="0.5em"
          overflow="hidden"
          as="form"
          onSubmit={() => history.push(`/checkout/18309`)}
        >
          <Flex p="0.5rem 0.75rem" fontSize="0.75rem" color="textGray">NTD</Flex>
          <Input
            onChange={(e) => donate(e.target.value)}
            value={money}
            border="none"
            borderLeft="1px solid black"
            borderRight="1px solid black"
            borderRadius="0"
            type="number"
          />
          <Button fontSize="0.75rem" bg="bgYellow" type="submit" border="none">贊助</Button>
        </Flex>
      </Board>
      {products.map((d) => (
        <ProductCard hovered={hovered} data={d} key={d.id}>
          {hoverable}
        </ProductCard>
      ))}
    </Box>
  )
}

export default Action
