import React, { forwardRef } from 'react';
import { Button } from "@chakra-ui/core";

import Link from './Link'

const ReButton = forwardRef(({ to, href, ...props }, ref) => {
  if (to || href) {
    return <Button as={p => <Link {...p} isDisabled={props.isDisabled} ref={ref} to={to} href={href} />} {...props} />
  }
  return <Button ref={ref} {...props} />
})

ReButton.defaultProps = {
  variantColor: 'yellow',
  fontFamily: 'heading',
  borderRadius: 0,
  bg: 'bgYellow',
  color: 'black',
  border: "2px",
  borderColor: "black",
  _hover: {
    bg: 'yellow.300',
  },
  _active: {
    bg: 'yellow.300',
  },
}

ReButton.Secondary = props => (
  <ReButton variantColor="green" {...props} />
)

ReButton.Danger = props => (
  <ReButton variantColor="red" {...props} />
)

ReButton.BlackOutline = props => (
  <ReButton
    borderRadius="1.5em"
    variantColor="black"
    bg="white"
    variant="outline"
    {...props}
  />
)

ReButton.Invisible = props => (
  <ReButton
    variantColor="ghost"
    px="0"
    py="0"
    bg="transparent"
    color="white"
    border="none"
    {...props}
    _hover={Object.assign({
      bg: 'transparent',
    }, props._hover)}
    _active={Object.assign({
      bg: 'transparent',
    }, props._active)}
  />
)

export default ReButton
