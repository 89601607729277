import React from 'react'
import isArray from 'lodash/isArray'

import Box from '../../../components/Box'
import Container from '../../../components/Container'
import Text from '../../../components/Text'
import Flex from '../../../components/Flex'
import Button from '../../../components/Button'
import Image from '../../../components/Image'
import Heading from '../../../components/Heading'
import Circle from '../../../components/Circle'

import step1 from './step_1.png'
import step2 from './step_2.png'
import step3 from './step_3.png'
import section2 from './section.png'
import theme from '../../../components/ThemeProvider/theme'

const contents = [
  {
    title: '100題的設計方法',
    text: ['參考國內外社會指標，\n選取9+1個領域', '每個領域中，\n細拆4-5個重要課題', '選取該課題的關鍵指標，\n選出數據題目'],
    img: section2
  },
  {
    title: '3種題型的設計',
    text: '「人之所以會迷路，不是因為無知，而是因為自以為知。」－盧梭\n\n很多時候，我們做出錯誤判斷並非因為笨或無知，而是因為我們的大腦習慣「憑感覺」做決定。人在用直覺看待事物時產生的直觀印象，很容易被感性或外力因素扭曲。要做出更好的判斷，並非死背所有數據，而是練習用數據思考。這份測驗從數據識讀的3個基礎出發設計不同的題型，作為衡量個人認知的工具、也同時作為練習數據素養的評量。',
    content: [
      {
        label: '趨勢｜過去20年來趨勢如何變化',
        desc: '觀察趨勢可以判斷事情變化的方向，而拉長時間，才能看見相對完整的趨勢。確立統計方法相同的一段時間，觀察數據的走勢，思考可能造成變化的原因。',
        src: step1
      },
      {
        label: '比較｜一個指標中誰的數值較大',
        desc: '沒有比較的數字沒有意義，用不同的基準做比較，才能看見所在的位置。找尋適合的分母作組別內的比較，進一步，用同類別的相關指標進行組別間的比較。',
        src: step2
      },
      {
        label: '組成｜特定族群每10個中占幾個',
        desc: '檢視整體分佈與各組差異，從不同角度分析事情，才能見樹又見林。先選擇一個具代表性的指標，觀察整體統計的分佈、再進一步檢視出現差異的組別。',
        src: step3
      },
    ]
  },
  {
    title: '挑選數據的標準',
    text: '「數據會說話，但也會說謊。」\n\n一份數據的可信度和發布單位並沒有絕對的關係，真正的關鍵其實在於細節。因此，我們設定了3個檢核標準，來選擇測驗中的100個數據：',
    content: [
      {
        label: '1.選擇「有公開統計方法」的數據',
        desc: '很多時候我們對數據的解讀其實和真實定義天差地遠。透過檢視統計項目的定義及統計方法，才能確保數據與社會課題的指標一致。'
      },
      {
        label: '2.選擇「統計期間超過5年」的數據',
        desc: '數據容易受到單年度特定事件或抽樣誤差的影響。選擇連續發布5年以上的統計報告，通常較能確保數據的穩定性。'
      },
      {
        label: '3.選擇「統計方法一致」的數據',
        desc: '數據的統計方法不時會隨政策、法規變革。確認選取的資料，統計方法及定義一致，才能進行跨年度、跨類別的比較。'
      },
    ]
  },
]

const updateText = [
  {
    label: '更好懂的題目敘述',
    desc: '修正部分題目的文字，減少填答過程中可能的困惑！'
  },
  {
    label: '更精準的詳解計算',
    desc: '針對「近5年平均」的題目修正詳解計算方式，重新以各年度原始數值計算五年平均的數值，但不會影響參考答案。'
  },
  {
    label: '更完整的詳解圖表',
    desc: '在結果分析的頁面中，我們重新修正了「逐題詳解」的圖表呈現，搭配題目提供更完整的對應數值！'
  },
]

const Way = () => {
  return (
    <Box pt={theme.headerHeight}>
      <Container py="1.75em" px="2em" maxWidth="600px">
        <Heading textAlign="center" borderBottom="1px solid" pb="1rem" fontSize="1.375em">題目設計說明</Heading>
        {contents.map(({ title, text, content, img }, i) => (
          <Box borderTop={i && '1px solid'} py="1.25em" key={i}>
            <Box borderLeft="5px solid" pl="0.375em" lineHeight="1">{title}</Box>
            {isArray(text) ?
              <Flex mt="1em">
                <Flex width={1 / 2} flexDirection="column" justifyContent="space-between">
                  {text.map((t, index) => (
                    <Box flex={index !== text.length - 1 && 1} position="relative" pl="1.5em" fontSize="0.875em" whiteSpace="pre-wrap" key={index}>
                      {t}
                      {index !== text.length - 1 && (
                        <Box
                          position="absolute"
                          width="1.75px"
                          bg="black"
                          top="0.375em"
                          bottom="-0.375em"
                          left="0.375em"
                          transform="translateX(-50%)"
                        />
                      )}
                      <Box position="absolute" width="0.75em" top="0.25em" left="0">
                        <Circle border="2px solid black" bg="white" />
                      </Box>
                    </Box>
                  ))}
                </Flex>
                <Box width={1 / 2}>
                  <Image src={img} />
                </Box>
              </Flex>
              :
              <Text whiteSpace="pre-wrap" fontSize="0.875em" my="0.875em">{text}</Text>}
            <Box>
              {content && content.map(({ label, src, desc }, k) => (
                <Flex
                  flexDirection={k % 2 && 'row-reverse'}
                  py={i ? '0.5em' : '1.75em'}
                  alignItems="center"
                >
                  <Box flex={1}>
                    <Flex alignItems="center">
                      {!i && <Text fontSize="2.5em" mr="0.5rem">{k + 1}</Text>}
                      <Text fontSize="0.875em">{label}</Text>
                    </Flex>
                    <Text mt="0.625em" fontSize="0.75em">{desc}</Text>
                  </Box>
                  {src &&
                    <Box width="50%" ml={k % 2 ? 0 : '1em'} mr={k % 2 ? '1em' : 0}>
                      <Image src={src} />
                    </Box>
                  }
                </Flex>
              ))}
            </Box>
          </Box>
        ))}
        <Box fontSize="0.875em" borderTop="1px solid" borderBottom="1px solid" py="1.25rem">
          <Box borderLeft="5px solid" pl="0.375em" fontSize="1rem" lineHeight="1">測驗更新</Box>
          <b>v.1 2020.12.7</b><br />
          初版上線<br />
          <br />
          <b>v.2 2021.06.30</b><br />
          根據v.1上線後收到的回饋，我們做了以下調整：<br />
          <ul>
            {updateText.map(({ label, desc }, i) => (
              <Box key={i} py="0.5rem">
                <Box as="li" ml="1rem">{label}</Box>
                <Text mt="0.625em" fontSize="0.75rem">{desc}</Text>
              </Box>
            ))}
          </ul>
        </Box>
        <Text mt="1em">
          製作團隊從2019年開始有系統性的整理資料、設計測驗、並經過數次的反覆測試，完成了第一個以臺灣的重要社會議題出發的認知測驗。未來測驗將持續改版與修正，我們希望在過程中能了解你的意見，讓我們能做得更好！<br /><br />
          如果你對測驗及題目有任何疑問，歡迎來訊和我們討論，我們非常期待聽到任何改進的建議！
        </Text>
        <Box textAlign="center" mt="0.5em">
          <Button to="/contact">聯絡我們</Button>
        </Box>
      </Container>
    </Box>
  )
}

export default Way
