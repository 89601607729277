import React, { useEffect, useState, createElement } from 'react'
import { Spinner, Icon } from '@chakra-ui/core'
import get from 'lodash/get'
import size from 'lodash/size'
import * as d3fetch from 'd3-fetch'
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTheme,
  VictoryBar,
  VictoryLabel,
  VictoryVoronoiContainer,
} from 'victory'
import { format } from 'd3-format'

import Heading from '../../components/Heading'
import SVG from '../../components/SVG'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Text from '../../components/Text'
import theme, { responsive } from '../../components/ThemeProvider/theme'
import useResponsive from '../../contexts/mediaQuery/useResponsive'
import useIsLarge from '../../contexts/largeFont/useIsLarge'

const parseNumber = str => {
  const isPercent = str.includes('%')
  return isPercent ? 1 * (str.substr(0, str.length - 1)) / 100 : 1 * (str.replace(/,/g, ''))
}

const perForm = format('.0%')
const dForm = format('~s')
const lForm = format('.1f')
const lPerForm = format('.1%')

const getPadding = (l) => {
  if (l < 3) return 120
  if (l < 4) return 80
  if (l < 6) return 40
  return 30
}

const parseData = values => {
  if (values.length && values[0] && values[0][0]) {
    const title = values[0][0]
    const data = []
    const meta = {}

    for (let index = 0; index < values.length; index += 1) {
      if (index > 0 && values[index][0]) {
        data.push({
          x: values[index][0],
          y: parseNumber(values[index][1]),
        })
      }
      if (values[index][3] && values[index][4]) {
        meta[values[index][3]] = values[index][4]
      }
    }
    meta.isPercent = /%|％/.test(meta.單位)

    return {
      title,
      data,
      meta,
    }
  }
  return {}
}

const QuestionChart = ({ question, isOpen }) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState()
  const [requested, setRequested] = useState()
  const { isMobile } = useResponsive()
  const [isLarge] = useIsLarge()

  const isLine = question.chartType === 'line'
  useEffect(() => {
    if (!data && !requested && isOpen) {
      setLoading(true)
      setRequested(true)
      d3fetch.text(`/charts/${question.keyword}.csv`).then(txt => {
        const values = txt.split('\n').map(l => l.split(','))
        const d = parseData(values)
        if (size(d)) {
          setData({
            ...d,
            maxValue: Math.max(...d.data.map(dd => dd.y)),
          })
        }
        setLoading(false)
      }).catch(e => {
        setLoading(false)
      })
    }
  }, [isOpen, data, requested, question.keyword])
  const note = data && (
    <Box
      opacity="0.6"
      textAlign="justify"
      fontSize={isLarge ? '0.8em' : '12px'}
      pl={isLarge && '4%'}
      mt="1em"
    >資料來源：{get(data, ['meta', '資料來源'])}</Box>
  )
  return (
    <>
      <Flex flexDirection={isLarge ? 'column' : 'row'}>
        <Flex width={!isLarge && '30%'} flexDirection="column" justifyContent="space-between" mr={!isLarge && 2} mb={isLarge && '1em'} pl={isLarge && '4%'}>
          <Heading fontSize={responsive('3em', '4em')} lineHeight="1">{question.keyword}</Heading>
          {!isMobile && !isLarge && note}
        </Flex>
        <Box width={!isLarge && '70%'}>
          {loading ? (
            <Box.Relative pt={`${9 / 16 * 100}%`}>
              <Box.AbsCenter><Spinner color="primary" /></Box.AbsCenter>
            </Box.Relative>
          ) : (
            data ? (
              <SVG viewBox="0 0 400 225">
                <VictoryChart
                  standalone={false}
                  theme={VictoryTheme.material}
                  width={400}
                  height={225}
                  minDomain={{ y: 0 }}
                  domainPadding={{ x: isLine ? 20 : getPadding(data.data.length), y: 20 }}
                  // domainPadding={{ x: 20, y: 20 }}
                  scale={isLine ? 'linear' : 'ordinal'}
                  padding={{ top: 40, left: 50, bottom: 30, right: 20 }}
                  containerComponent={<VictoryVoronoiContainer/>}
                  title={data.title}
                >
                  {createElement(isLine ? VictoryLine : VictoryBar, {
                    data: data.data,
                    labelComponent: <VictoryLabel/>,
                    labels: ({ datum }) => !isLine ? (data.meta.isPercent ? lPerForm : (data.meta.精確度 ? format(`.${data.meta.精確度 * 1}f`) : lForm))(datum.y) : '',
                    style: {
                      data: {
                        stroke: theme.colors.primary,
                        strokeWidth: 4,
                        fill: isLine ? 'transparent' : theme.colors.primary,
                      },
                    },
                  })}
                  <VictoryAxis fixLabelOverlap />
                  <VictoryAxis
                    dependentAxis
                    label=""
                    style={{
                      axisLabel: {
                        padding: 40,
                      }
                    }}
                    tickCount={data.meta.tick}
                    tickFormat={data.meta.isPercent ? (data.meta.精確度 ? format(`.${data.meta.精確度 * 1}%`) : perForm) : dForm}
                  />
                </VictoryChart>
                {!data.meta.isPercent && (
                  <text x="44" y="36" textAnchor="end" fontSize="10" fill="#455a64">
                    {get(data, ['meta', '單位'], '').split('|').map((l, i) => (
                      <tspan key={i} x={44} dy={12 * i}>{l}</tspan>
                    ))}
                  </text>
                )}
                <text x={18} y={20} fontSize="18" fontWeight="700">{data.title}</text>
              </SVG>
            ) : (
              <Text my="2em" textAlign="center" color="red.500">
                <Icon name="warning" size="1.25em" mr="0.5em" />圖表顯示異常
              </Text>
            )
          )}
        </Box>
      </Flex>
      {(isMobile || isLarge) ? note : null}
    </>
  )
}

export default QuestionChart
