import React, { useMemo } from 'react'
import { scaleSqrt } from 'd3-scale'
import { format } from 'd3-format'
import { withSize } from 'react-sizeme'
import {useSpring, animated} from 'react-spring'

import max from 'lodash/max'
import sum from 'lodash/sum'

import Face from '../../../components/Face'
import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Text from '../../../components/Text'
import useIsLarge from '../../../contexts/largeFont/useIsLarge'
import { responsive } from '../../../components/ThemeProvider/theme'

const text = ['樂觀', '中立', '悲觀']

const perFormat = format('.0%')

const AllEmotion = ({ totalEmotion, size, visible }) => {
  const [isLarge] = useIsLarge()

  const emoSum = sum(totalEmotion)
  const maxNum = max(totalEmotion)
  const scale = useMemo(() => scaleSqrt().domain([0, 1]).range([0, size.width * 0.3]), [size])
  const spring = useSpring({
    f: visible ? 1 : 0,
    config: {
      duration: 1000,
    },
  })
  return (
    <>
      <Text my="1em" fontSize="0.875em">看看你的答題傾向樂觀還是悲觀呢？</Text>
      <Box.Relative mt="1em">
        <Box.BottomDash
          position="absolute"
          top="50%"
          left="0"
          right="0"
          height="0.375em"
          borderColor="black"
          backgroundSize="7px 100%"
          transform="translateY(-50%)"
        />
        <Flex position="relative" justifyContent="space-between">
          {totalEmotion.map((v, i) => {
            const per = v / emoSum
            return (
              <Box.Relative key={i} width={scale(per)} textAlign="center">
                <Box pt="175%">
                  <Box.Absolute top="50%" transform="translateY(-50%)" width="100%">
                    <animated.div style={{ transform: spring.f.interpolate(f => `scale(${f})`), transformOrigin: '50% 50%' }}>
                      <Face n={i} active={maxNum === v} />
                    </animated.div>
                  </Box.Absolute>
                  <Box.Absolute top="-0.375em" left="0" right="0" fontSize="1.5em">
                    <animated.span>
                      {spring.f.interpolate(f => perFormat(f * per))}
                    </animated.span>
                  </Box.Absolute>
                  <Box.Absolute bottom="-0.375em" left="0" right="0" fontSize={responsive(isLarge ? '1em' : '1.25em', '1.25em')}>{text[i]}</Box.Absolute>
                </Box>
              </Box.Relative>
            )
          })}
        </Flex>
      </Box.Relative>
    </>
  )
}

export default withSize()(AllEmotion)
