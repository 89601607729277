import { Formik, Form } from 'formik';
import React from 'react'
import { Switch, Route, useHistory } from 'react-router-dom';
import useFetch from 'use-http'
import { useSessionStorage } from 'react-use';

import Homepage from './Homepage'
import StartPage from './Start'
import TaipeiExamQuestionPage from './QuestionPage';
import TaipeiExamResultPage from './ResultPage';
import FullpageLoading from '../../components/FullpageLoading';

const TaipeiExam = () => {
  const history = useHistory()
  const { post, loading } = useFetch("/answer") // onMount
  const [, setSessionResult] = useSessionStorage('TPE_COG')
  return (
    <Formik
      initialValues={{
        q: [],
        qDuration: []
      }}
      onSubmit={(values) => {
        post(values.q).then(() => {
          setSessionResult(values.q)
          history.push('/tpe/result')
        })
      }}
    >
      <Form>
        <Switch>
          <Route exact path="/tpe" component={Homepage} />
          <Route path="/tpe/start" component={StartPage} />
          <Route path="/tpe/q/:no" component={TaipeiExamQuestionPage} />,
          <Route path="/tpe/result" component={TaipeiExamResultPage} />,
        </Switch>
        {loading && <FullpageLoading />}
      </Form>
    </Formik>
  )
}

export default TaipeiExam
