import { IconButton } from '@chakra-ui/core';
import React from 'react'
import { IoMdArrowDropright, IoMdArrowDropleft } from 'react-icons/io';

const ChevButton = ({ onClick, prev, ...props }) => {
  return (
    <IconButton
      color="black"
      bg="primary"
      _hover={{
        bg: 'yellow.300',
      }}
      _active={{
        bg: 'yellow.300',
      }}
      border="2px solid"
      size="md"
      isRound
      fontSize="1.875em"
      onClick={onClick}
      icon={prev ? IoMdArrowDropleft : IoMdArrowDropright}
      {...props}
    />
  )
}

export default ChevButton
