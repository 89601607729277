import React, { useRef } from 'react'
import { Field } from 'formik'

import get from 'lodash/get'

import Button from '../../../components/Button'
import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Text from '../../../components/Text'
import { responsive } from '../../../components/ThemeProvider/theme'
import useResponsive from '../../../contexts/mediaQuery/useResponsive'
import { Icon } from '@chakra-ui/core'

const Hint = ({ bg, color = 'text', children }) => (
  <Box.Absolute as={Flex} top="0" right="0" bottom="0" alignItems="center" bg={bg}>
    <Text px={responsive('6', '8', '6', '16')} color={color}>{children}</Text>
  </Box.Absolute>
)

const Option = ({ name, label, index, onConfirm, confirmed, direct, showAns, isAnswer, ...props }) => {
  const inputRef = useRef()
  const { isMobile } = useResponsive()
  return (
    <Field name={name} type="radio" value={String(index)}>
      {({ field, form }) => {
        const selected = get(form.values, name)
        return (
          <Box {...props}>
            <Box.Relative borderRadius="4em" overflow="hidden">
              <Button
                fontFamily="body"
                width="100%"
                isDisabled={direct ? Boolean(selected) : (confirmed && !field.checked) || selected == '-1'}
                height="auto"
                justifyContent="flex-start"
                fontSize="1.125em"
                lineHeight="3"
                fontWeight="normal"
                variant="unstyled"
                display="inline-flex"
                borderRadius="4em"
                border="3px solid"
                color="black"
                // _focus={isMobile ? { bg: 'transparent' } : { bg: 'bgYellow' }}
                _hover={isMobile ? { bg: 'transparent' } : { bg: 'bgYellow' }}
                bg={field.checked ? 'bgYellow' : 'white'}
                borderColor="black"
                onClick={() => inputRef.current.click()}
              >
                <Box.Invisible>
                  <input {...field} type="radio" ref={inputRef} />
                </Box.Invisible>
                <Box.Inline mx="6">
                  {String.fromCharCode(65 + index)}. {label}
                </Box.Inline>
              </Button>
              {field.checked && (confirmed ? (
                !showAns && <Hint>已送出</Hint>
              ) : (
                <Box.Absolute top="0" right="0" bottom="0">
                  <Button
                    height="100%"
                    bg="black"
                    color="white"
                    fontSize="1rem"
                    px={responsive('6', '8', '6', '16')}
                    onClick={onConfirm}
                    _hover={{ bg: 'black' }}
                    _active={{ bg: 'black' }}
                  >送出</Button>
                </Box.Absolute>
              ))}

              {showAns && confirmed && isAnswer && (
                <Hint><Icon name="check" /></Hint>
              )}
              {showAns && confirmed && selected == index && !isAnswer && (
                <Hint bg="black" color="white"><Icon name="close" /></Hint>
              )}
            </Box.Relative>
          </Box>
        )
      }}
    </Field>
  )
}

export default Option
