import React from 'react'

import {
  VictoryChart,
  VictoryGroup,
  VictoryTheme,
  VictoryArea,
  VictoryContainer,
  VictoryPolarAxis
} from 'victory';

const Radar = ({ values, axes, avg }) => {
  return (
      <VictoryChart
        width={400}
        height={360}
        polar
        startAngle={90}
        endAngle={-270}
        theme={VictoryTheme.material}
        domain={{ y: [ 0, 100 ] }}
        range={{ y: [ 0, 125 ] }}
        animate
        containerComponent={
          <VictoryContainer
            style={{
              pointerEvents: "auto",
              userSelect: "auto",
              touchAction: "auto"
            }}
          />
        }
      >
        <VictoryGroup
          colorScale={["#f8e300"]}
          style={{ data: { fillOpacity: 1, strokeWidth: 3, stroke: 'black' } }}
        >
          <VictoryArea data={values}/>
        </VictoryGroup>
        {axes.map(({ padding, label }, i) => {
          return (
            <VictoryPolarAxis
              key={i}
              dependentAxis
              style={{
                axisLabel: {
                  padding,
                  fontSize: 16,
                  fill: 'black',
                },
                axis: { stroke: "black" },
                grid: { stroke: "black", strokeWidth: 2, strokeDasharray: 0, opacity: 0.02 },
                tickLabels: { fill: "transparent", }
              }}
              axisValue={i + 1}
              label={() => [`${values[i].y}%`, label]}
              labelPlacement="vertical"
              tickValues={[25, 50, 75]}
            />
          );
        })}
        {typeof avg === 'number' && (
          <VictoryPolarAxis
            dependentAxis
            style={{
              ticks: {
                opacity: 0,
              },
              axis: { stroke: "transparent", strokeWidth: 3 },
              grid: { stroke: "black", strokeWidth: 3, strokeDasharray: '4 8' },
            }}
            tickFormat={() => ""}
            tickValues={[avg]}
          />
        )}
        <VictoryPolarAxis
          style={{
            ticks: {
              opacity: 0,
            },
            axis: { stroke: "black", strokeWidth: 3 },
            grid: { stroke: "transparent", opacity: 1 },
          }}
          tickFormat={() => ""}
        />
      </VictoryChart>
  )
}

export default Radar
