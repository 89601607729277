import React, { useEffect } from 'react'
import { Heading } from "@chakra-ui/core";
import { useHistory } from 'react-router';

import Box from '../../../components/Box'
import Container from '../../../components/Container'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import ToDo from './ToDo';

import support from './support.png'
import datafriend from './datafriend.png'
import speech from './speech.jpg'
import theme from '../../../components/ThemeProvider/theme';
import loader from '../../../imageLoader';
import Action from '../Analyze/Action';

export const program = [
  {
    icons: datafriend,
    adj: '數據好朋友組合1份',
    cost: '600',
    content: [
      '數據問題探究流程',
      '數據實用技巧攻略',
      '數據使用Checklist',
      '*目前無單獨販售計畫，臺澎金馬免運費',
    ],
    id: 18032,
  },
  // {
  //   icons: clothes,
  //   adj: '穿上數據，邊走邊推廣議題',
  //   cost: '1,000',
  //   content: ['100組數據供任選', '確認後，將於10個工作天內出貨'],
  //   id: 18033,
  //   options: keywords,
  // },
  // {
  //   icons: post,
  //   adj: '年末送禮最佳選擇',
  //   cost: '2,000',
  //   content: ['觀茶臺灣茶禮盒 1 份（30入）', '線實臺灣海報 1 份（2入）'],
  //   id: 18034,
  // },
  // {
  //   icons: tea,
  //   number: [10, 1, 1],
  //   adj: '和親友一起提升數據素養',
  //   cost: '6,000',
  //   content: ['媒體數養指南 10 份', '觀茶臺灣茶禮盒 1 份（30入）', '線實臺灣海報 1 份（2入）'],
  //   id: 18035,
  // },
  {
    icons: speech,
    adj: '指定地點演講1場',
    cost: '16,000',
    content: [
      '由你選擇場地，我們前往分享！',
      '講座約90分鐘，加贈10份數據好朋友組合',
      '贊助後將有專人聯絡確認時間，6個月內實現',
      '*演講地點只要在臺澎金馬，不論多遠都可以！',
    ],
    id: 18036,
  },
]

const Support = ({ location }) => {
  const history = useHistory()
  useEffect(() => {
    loader(program.map(p => p.icons))
  }, [])
  return (
    <Box pt={location && theme.headerHeight} mx="auto" maxWidth="50em">
      <Container py="4">
        <Action />
        {/* <Box width="35%" mx="auto">
          <Image src={support} />
        </Box>
        <Heading as="h4" fontSize="1.5em" textAlign="center" mt="0.75rem" mx="1.625rem">支持我們<br />提升全臺數據識讀力</Heading>
        <Box my="0.75em" height="1px" bg="black" />
        <Text>
          成為我們的支持夥伴，讓我們不只在網路上，更能到全臺各地推廣數據識讀力
        </Text>
        <Heading mt="1.25em" fontSize="1.5em" textAlign="center" fontWeight="bold">2021下半年，我們預計製作</Heading>
        <ToDo />
        <Box mt="1.25em">
          {program.map(({ cost, content, icons, number, adj, title, id }, i) => (
            <Box border="1.5px solid" p="1em" mt="1em" key={i} role="button" onClick={() => history.push(`/checkout/${id}`)}>
              <Box px="0.5em">
                <Image src={icons} />
              </Box>
              <Text lineHeight="1.5" mt="2rem" fontSize="2em">NT${cost}</Text>
              <Box borderLeft="8px solid" pl="0.5rem" lineHeight="1" fontSize="1.25em">{adj}</Box>
              <Box pt="1em">
                {/* <Text>內容:</Text> */}
                {/* <Box as="ul">
                  {content.map((text, key) => (
                    <Box ml="1.125em" as="li" key={key}>{text}</Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ))} */}
        {/* </Box> */}
      </Container>
    </Box>
  )
}

export default Support
