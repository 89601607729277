import React, { createElement } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Formik } from 'formik'
import * as yup from 'yup'
import { useFirebase } from 'react-redux-firebase'
import mapValues from 'lodash/mapValues'
import nativeFirebase from 'firebase'
import { useHistory } from 'react-router-dom'

// import HomePage from './containers/HomePage'
import HomePageOK from './containers/HomePageOK'
import BeforeStart from './containers/BeforeStart'
import Success from './containers/Success'
import Reserve from './containers/Reserve'
import Introduction from './containers/Introduction'
import StagePage from './containers/StagePage'
import StageClear from './containers/StagePage/Clear'
import StageIntro from './containers/StagePage/Intro'
import QuestionPage from './containers/QuestionPage'
import InfoPage from './containers/InfoPage'
import ResultPage from './containers/ResultPage'
import CheckResult from './containers/CheckResult'
import Contact from './containers/ResultPage/Contact';
import About from './containers/ResultPage/About';
import Way from './containers/ResultPage/Way';
import Support from './containers/ResultPage/Support'
import Checkout from './containers/ResultPage/Support/Checkout'
import Thanks from './containers/ResultPage/Support/Thanks'
// import NotFoundPage from './containers/NotFoundPage'
import Start from './containers/Start'
import Layout from './containers/Layout'

import FullpageLoading from './components/FullpageLoading'

import FormStorage, { STORAGE_KEY } from './FormStorage'
import useUser from './contexts/user/useUser';
import TaipeiExam from './containers/TaipeiExam';
import useIsLarge from './contexts/largeFont/useIsLarge';
import sanitizer from './sanitizer';
import useQs from './contexts/qs/useQs';

const prevValue = localStorage.getItem(STORAGE_KEY)

const shape = {
  email: yup.string().email('請輸入正確的E-mail格式'),
  gender: yup.string().required('此欄位必填'),
  age: yup.number().min(0).max(120).required('此欄位必填'),
  city: yup.string().required('此欄位必填'),
}

const schema = yup.object().shape(shape)

const paths = [
  { exact: true, path: '/', component: HomePageOK },
  { path: '/speech', component: p=> <HomePageOK ver="演講版" {...p} /> },
  { path: '/school', component: p=> <HomePageOK ver="教育版" {...p} /> },
  { path: '/clear', component: p=> <HomePageOK ver="大字版" large {...p} /> },
  { path: '/checkResult', component: CheckResult },
  { path: '/reserve', component: Reserve },
  { path: '/quickStart', component: p => <BeforeStart {...p} isEdu /> },
  { path: '/beforeStart', component: BeforeStart },
  { path: '/introduction', component: Introduction },
  { path: '/start', component: Start },
  { path: '/registered', component: Success },
  { path: '/stage/:key/q/:no', component: props => <QuestionPage key={props.match.params.no} {...props} /> },
  { path: '/stage/:key/clear', component: StageClear },
  { path: '/stage/:key', component: StageIntro },
  { path: '/stage', component: StagePage },
  { path: '/info', component: InfoPage },
]

const outformPaths = [
  { path: '/result/:key/:tab', component: ResultPage },
  { path: '/result/:key', component: ResultPage },
  { path: '/support', component: Support },
  { path: '/checkout/:id', component: Checkout },
  { path: '/thanks', component: Thanks },
  { path: '/design', component: Way },
  { path: '/about', component: About },
  { path: '/contact', component: Contact },
  { path: '/tpe', component: TaipeiExam, noHeader: true },
]

const FormilkRoutes = () => {
  const firebase = useFirebase()
  const history = useHistory()
  const [isLarge] = useIsLarge()
  const qs = useQs()
  const { isLoading, user, hasUser, answerKey } = useUser()
  if (isLoading) return <FullpageLoading />
  return (
    <Formik
      initialValues={prevValue
        ? Object.assign(JSON.parse(prevValue), hasUser ? { email: user.value.email } : {})
        : mapValues(shape, (val, key) => key === 'email' && hasUser ? user.value.email : '')}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        let ansKey = answerKey
        if (answerKey) {
          await firebase.update(`answers/${answerKey}`, {
            uid: hasUser ? user.key : '',
            isLarge,
            completedAt: nativeFirebase.database.ServerValue.TIMESTAMP,
          })
        } else {
          const sanitized = sanitizer(values)

          const { key } = await firebase.push(`answers`, {
            ...sanitized,
            ref: qs.get('ref'),
            utm: {
              source: qs.get('utm_source') || '',
              medium: qs.get('utm_medium') || '',
              campaign: qs.get('utm_campaign') || '',
            },
            isLarge,
            completedAt: nativeFirebase.database.ServerValue.TIMESTAMP,
          })
          ansKey = key
        }
          // resetForm()
        history.push(`/result/${ansKey}/analyze`)
        setSubmitting(false)
      }}
    >
      <FormStorage>
        <Switch>
          <Redirect path="/beta" to="/" />
          {paths.map(({ noHeader, component, ...cfg }) => (
            <Route
              key={cfg.path}
              {...cfg}
              component={p => <Layout noHeader={noHeader}>{createElement(component, p)}</Layout>}
            />
          ))}
        </Switch>
      </FormStorage>
    </Formik>
  )
}

function App() {
  return (
    <>
      <FormilkRoutes />
      <Switch>
        {outformPaths.map(({ noHeader, component, ...cfg }) => (
          <Route
            key={cfg.path}
            {...cfg}
            component={p => <Layout noHeader={noHeader}>{createElement(component, p)}</Layout>}
          />
        ))}
      </Switch>
    </>
  );
}

export default App;
