export default {
  apiKey: "AIzaSyAY_BDIP2-x9VHCZwttOMxvgJKQRACJg8o",
  authDomain: "tw-cognition.firebaseapp.com",
  databaseURL: "https://tw-cognition.firebaseio.com",
  projectId: "tw-cognition",
  storageBucket: "tw-cognition.appspot.com",
  messagingSenderId: "795345465219",
  appId: "1:795345465219:web:2573dfa0afc892dfe050b6",
  measurementId: "G-9YS58K5XZ3"
};
