import React, { useState, useEffect, useMemo } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { CircularProgress, CircularProgressLabel, Stack } from "@chakra-ui/core";
import { useField } from 'formik'
import get from 'lodash/get'
import ReactMarkdown  from 'react-markdown'

// import Container from '../../components/Container'
import Text from '../../components/Text'
import Heading from '../../components/Heading'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
import theme, { responsive } from '../../components/ThemeProvider/theme';
import useResponsive from '../../contexts/mediaQuery/useResponsive';

import useQs from '../../contexts/qs/useQs'

import { questionsByCategory, allQuestionTypes } from './data'
import { stageByKey } from '../StagePage/data'

import Option from './Option'
import useIsLarge from '../../contexts/largeFont/useIsLarge';

let timer
let navigation
let startT
const seconds = 20

const QuestionPage = ({ match: { params: { key, no } } }) => {
  const stage = stageByKey[key]
  const questions = questionsByCategory[get(stage, 'name')]
  const question = get(questions, no - 1)
  const qIndex = get(question, 'no') - 1
  const name = `q.${qIndex}`
  const history = useHistory()
  const [isLarge] = useIsLarge()
  const realSec = useMemo(() => seconds * (isLarge ? 1.5 : 1), [isLarge])
  const [time, setTime] = useState(realSec)
  const [field, , meta] = useField({ name })
  const [, , { setValue }] = useField({ name: `stageFinish.${key}` })
  const [, , durMeta] = useField({ name: `qDuration.${qIndex}` })
  const [confirmed, setConfirm] = useState(Boolean(field.value))
  const params = useQs()
  const showAns = params.get('showAns')
  // const { isMobile } = useResponsive()

  const delayGoNext = () => {
    durMeta.setValue(new Date() - startT)
    navigation = setTimeout(() => {
      if (no == questions.length) {
        setValue(+new Date())
        history.push(`/stage/${key}/clear`)
      } else {
        history.push(`/stage/${key}/q/${+no + 1}`)
      }
    }, 1000)
  }

  const countdown = (prevTime) => {
    timer = setTimeout(() => {
      if (document.visibilityState === 'visible') {
        setTime(prevTime - 1)
        if (prevTime - 1) {
          countdown(prevTime - 1)
        } else {
          meta.setValue('-1')
          delayGoNext()
        }
      } else {
        countdown(prevTime)
      }
    }, 1000)
  }

  const onAnswer = () => {
    clearTimeout(timer)
    timer = null
    delayGoNext()
  }

  useEffect(() => {
    if (confirmed) {
      onAnswer()
    }
  }, [confirmed])

  useEffect(() => {
    countdown(time)
    startT = new Date()
    return function cleanup() {
      if (timer) clearTimeout(timer)
      if (navigation) clearTimeout(navigation)
    }
  }, [])

  if (!question) return <Redirect to={`/stage/${key}`} />
  return (
    <Box pt={theme.headerHeight}>
      <Flex
        alignItems="center"
        justifyContent="space-around"
        borderBottom="2.5px solid"
        mx={responsive('0', '10em')}
        px="10"
        py={responsive('2', '6')}
        mt={responsive(0, '4em')}
      >
        <Box flex="1">
          <Heading fontSize="1.875em" fontWeight="900">{question.category}</Heading>
          {/* <Text color="white">{question.keyword}</Text> */}
        </Box>
        <Box>
          <CircularProgress value={time / realSec * 100} color="clock">
            <CircularProgressLabel>
              <Text fontSize="1.125rem">{time}</Text>
            </CircularProgressLabel>
          </CircularProgress>
        </Box>
      </Flex>

      <Box
        as={Flex}
        pt={responsive('4', '2em')}
        px={responsive('2', 0)}
        mx={responsive('0', '10em')}
        flexDirection={responsive('column', null, 'row')}
        justifyContent="center"
        alignItems={responsive('flex-start', 'center')}
      >
        <Box p="4" flex="1">
          <Flex justifyContent="space-between" alignItems="center" mb="4">
            <Heading as="h4" fontSize={responsive('1.25em', '2em')} >
              第{no}題
            </Heading>
            <Flex justifyContent="flex-end" flex={1}>
              {allQuestionTypes.map((typeName, i) => (
                <Box
                  key={i}
                  border="2px solid"
                  borderLeftWidth={i ? 0 : 2}
                  py="0.125em"
                  px="0.5em"
                  bg={typeName === question.type && 'custom.gray'}
                  color={typeName === question.type && 'white'}
                  borderColor="black"
                  fontWeight="500"
                >{typeName}</Box>
              ))}
            </Flex>
          </Flex>
          <Box fontSize={responsive('1.5em', '2.5em')}>
            <ReactMarkdown source={question.question} />
          </Box>
        </Box>
        <Stack width={responsive('100%', '100%', '40%')} my="4" px={responsive('12', '4')} spacing="6">
          {question.options.map((label, i) => (
            <Option
              key={i}
              name={name}
              label={label}
              index={i}
              confirmed={confirmed}
              showAns={showAns == 1}
              onConfirm={() => setConfirm(true)}
              isAnswer={question.answer == i}
              // onCancel={() => meta.setValue(null)}
            />
          ))}
        </Stack>
        {/* {question.helper && (
          <Box borderTop="1px solid black" p="2">
            <Text color="primary" lineHeight="taller" fontSize="1.25rem">名詞解釋</Text>
            <Text mb="2">{question.helper}</Text>
          </Box>
        )} */}
      </Box>
    </Box>
  )
}

export default QuestionPage
