import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import firebase from 'firebase'
import { mapValues } from 'lodash';
import sanitize from 'sanitize-html';
import { Stack } from '@chakra-ui/core';

import FormControl from '../../../components/FormControl'
import Box from '../../../components/Box'
import Text from '../../../components/Text'
import Container from '../../../components/Container'
import Button from '../../../components/Button'
import Heading from '../../../components/Heading'
import ModalModule from '../../../components/ModalModule'
import theme from '../../../components/ThemeProvider/theme';

const fields = [
  {
    label: '你的名字',
    name: 'name'
  },
  {
    label: '你的電子郵件',
    name: 'email'
  },
  {
    label: '你想詢問什麼',
    options: [
      '測驗意見回饋',
      '合作、演講聯繫',
      '贊助方案疑問',
      '其他事項詢問',
    ],
    name: 'type',
    type: 'select',
    placeholder: '請選擇',
  },
  {
    label: '請填入你的詳細疑問',
    name: 'ask',
    type: 'textarea',
  },
]

const schema = yup.object({
  name: yup.string().required('此欄位必填'),
  email: yup.string().email().required('此欄位必填'),
  type: yup.string().required('此欄位必填'),
  ask: yup.string().required('此欄位必填'),
})

const Contact = () => {
  const [submitted, setSubmitted] = useState()
  return (
    <>
      <Formik
        initialValues={{ name: '', email: '', phone: '', ask: '' }}
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            await firebase.functions().httpsCallable('handleContact')(mapValues(values, sanitize))
            setSubmitted(true)
            resetForm()
          } catch (e) {
            console.error(e)
          }
          setSubmitting(false)
        }}
      >
        {({ isSubmitting }) => (
          <Box pt={theme.headerHeight}>
            <Container py="1.75em" px="2.875em">
              <Heading textAlign="center" borderBottom="1px solid" pb="1rem" fontSize="1.375em">聯絡我們</Heading>
              <Form>
                <Stack spacing="4" my="4">
                  {fields.map(field => (
                    <FormControl {...field} isRequired key={field.name} />
                  ))}
                </Stack>
                <Box pt="0.875em" textAlign="center">
                  <Button.BlackOutline type="submit" p="2em" py="0.25em" isLoading={isSubmitting} isDisabled={submitted}>{submitted ? '已傳送' : '傳送'}</Button.BlackOutline>
                </Box>
              </Form>
            </Container>
          </Box>
        )}
      </Formik>
      <ModalModule open={submitted} close={() => setSubmitted(false)}>
        <Text fontSize="1.5em" textAlign="center" my="2em">詢問已送出，我們會盡速回覆</Text>
      </ModalModule>
    </>
  )
}

export default Contact
