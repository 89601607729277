import React from 'react';

import Box from './Box';
import FullpageHeight from './FullpageHeight'
import theme, { containerWidth, responsive } from './ThemeProvider/theme';

const Container = (props) => <Box {...props} />;

Container.defaultProps = {
  mx: 'auto',
  px: responsive('2', '4.25em'),
  maxWidth: containerWidth,
};

Container.displayName = 'Container';

Container.Fullpage = ({ px, my, withHeader, ...props }) => (
  <FullpageHeight px={px} pb={my} pt={withHeader ? theme.headerHeight : my}>
    <Box height="100%" position="relative" {...props} />
  </FullpageHeight>
)

Container.Fullpage.defaultProps = {
  mx: 'auto',
  my: responsive('2', '4'),
  px: responsive('2', '4'),
  maxWidth: containerWidth,
};

Container.FullpageBorder = ({ withHeader, ...props }) => (
  <Container.Fullpage withHeader={withHeader}>
    <Box height="100%" overflowX="hidden" overflowY="auto" {...props} />
  </Container.Fullpage>
)

Container.FullpageBorder.defaultProps = {
  border: "1px solid black",
  borderRadius: 'lg',
}

export default Container;
