import React, { useRef, useState, useCallback } from 'react';
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
import { Redirect, useHistory } from 'react-router-dom'
// import nativeFirebase from 'firebase'
import get from 'lodash/get'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/core";
import { json } from 'd3-fetch'

import Container from '../components/Container'
import Box from '../components/Box'
import Text from '../components/Text'
import FormControl from '../components/FormControl'
import BottomSnapButton from '../components/BottomSnapButton'
import Image from '../components/Image'
import Button from '../components/Button'
import { responsive } from '../components/ThemeProvider/theme';

import useUser from '../contexts/user/useUser'

// import introDoor from './intro-door-man.svg'
import useResponsive from '../contexts/mediaQuery/useResponsive';
import useQs from '../contexts/qs/useQs';

let userMemo
let userAnswerMemo

const BeforeStart = () => {
  const { setFieldValue, setFieldError, errors, values } = useFormikContext()
  const btnRef = useRef()
  const qs = useQs()
  const [loading, setLoading] = useState()
  const { user, setEmail, hasUser } = useUser()
  const { isMobile } = useResponsive()
  const terms = useDisclosure()
  // const [isVer] = useSessionStorage('TW_COG_VER')
  const isVerified = hasUser && user.value.emailVerified
  const [isOpen, setIsOpen] = useState(hasUser && !user.value.emailVerified)
  useFirebaseConnect([hasUser ? `answers/${user.answerKey}` : 'answers'])
  const userAnswer = useSelector(state => get(state.firebase.data, ['answers', hasUser ? user.value.answerKey : '']))
  const history = useHistory()
  userMemo = user
  userAnswerMemo = userAnswer
  const handleClick = useCallback(async () => {
    setLoading(true)
    try {
      const res = await json(process.env.NODE_ENV === 'production' ? '/requestValidationApp' : 'https://us-central1-tw-cognition.cloudfunctions.net/requestValidationAppLimiter', {
        method: 'POST',
        body: JSON.stringify({
          email: values.email,
          ref: qs.get('ref'),
          utm: {
            source: qs.get('utm_source') || '',
            medium: qs.get('utm_medium') || '',
            campaign: qs.get('utm_campaign') || '',
          },
        }),
        headers: {
          'content-type': 'application/json'
        },
      })
      if (res.error) {
        setFieldError('email', res.error)
      } else {
        await setEmail(values.email.toLowerCase())
        if (res.validated) {
          setTimeout(() => {
            history.push(userAnswerMemo && userAnswerMemo.completedAt ? `/result/${userMemo.value.answerKey}` : '/stage')
          })
        } else {
          setIsOpen(true)
        }
      }
    } catch (e) {
      console.error(e)
      setFieldError('email', e.message)
    }

    setLoading(false)
  }, [values.email, setEmail, history, setFieldError, setFieldValue])
  // if (!isVer) return <Redirect to="/" />
  return (
    <Container.Fullpage pt="6" px="6" maxWidth="45em" textAlign="center">
      <Box as={isMobile ? 'div' : Box.AbsCenter}>
        <Box as={isMobile ?  Box.AbsCenter : 'div'} width={responsive('90%', '100%')}>
          <Text fontSize={responsive('1.125em', '1.5em')} whiteSpace="pre-wrap" fontWeight="bold">
            {isVerified ? '你已通過驗證' : (
                '為避免測驗中意外跳離\n請先認證e-mail即時儲存作答記錄'
            )}
          </Text>
          <FormControl
            mt="2"
            mb="5"
            name="email"
            placeholder="email@example.com"
            isReadOnly={user}
            isRequired
            onKeyUp={e => e.keyCode === 13 && setTimeout(() => {
              if (btnRef.current) {
                btnRef.current.click()
              }
            })}
          />
          <Text fontSize="1em">
            {isVerified ? (
              <>
                若在測驗中因意外跳出<br />
                可以重新開啟此頁面<br />
                填入信箱，繼續進行測驗<br />
              </>
          ) : <>*輸入並點擊「點擊驗證信箱」即代表你同意本網站使用你的「電子信箱地址」提供服務<Button.Invisible color="black" height="auto" lineHeight="1.5" onClick={terms.onOpen}>(檢閱說明)</Button.Invisible></>}
          </Text>
          <Box pt={responsive('8', 0)} />
        </Box>

        <BottomSnapButton
          onClick={handleClick}
          isDisabled={!values.email || errors.email}
          isLoading={loading}
          ref={btnRef}
        >
          {isVerified ? '點擊開始測驗' : '點擊驗證信箱'}
        </BottomSnapButton>
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
          document.body.style.overflow = ''
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent border="2px solid" rounded="1.5em">
          <ModalHeader>驗證信已寄出</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb="4">
            <Text fontWeight="bold">
              已經寄出一封認證信到你的信箱<br />
              點擊信中連結完成認證！
            </Text>
            <Text mt="4">
              若沒有收到認證信，請檢查促銷或垃圾郵件<br />或關閉跳窗再次點擊發送
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={terms.isOpen}
        onClose={terms.onClose}
        isCentered
        size={responsive('24em', '32em')}
      >
        <ModalOverlay />
        <ModalContent border="2px solid" rounded="1.5em">
          <ModalHeader>個人資料蒐集說明</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb="4">
            <Box as="ul" ml="4">
              <li>本問卷依據個資法蒐集、處理及利用您的個人資料(如：電子郵件地址)。</li>
              <li>上述個人資料之蒐集、處理及利用，將僅限本問卷業務需要使用，並遵守個資法之規定，妥善保護您的個人資訊。</li>
              <li>依據個資法第3條規定，您可向本公司行使之個資權利包括：查詢、閱覽、複製、補充、更正、處理、利用及刪除。</li>
              <li>您可與本公司聯繫(hi@relab.cc)，本公司將儘速處理與回覆您的請求。</li>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container.Fullpage>
  );
};

export default BeforeStart;
