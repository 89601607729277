import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/core";
import get from 'lodash/get'
import { GoTriangleDown } from 'react-icons/go'
import { RiCheckLine } from 'react-icons/ri'
import { CgClose } from 'react-icons/cg'
import ReactMarkdown  from 'react-markdown'
import ReactGA from 'react-ga'

import Box from '../../components/Box'
import Button from '../../components/Button'
// import { responsive } from '../../components/ThemeProvider/theme';

import questions, { categoriesIssue } from '../QuestionPage/data'

import FilterButton from './FilterButton'
import QuestionAnswer from './QuestionAnswer';

const QuestionItem = ({ question, isOpen, selected, id, onChange }) => useMemo(() => {
  const isCorrect = selected == question.answer
  return (
    <AccordionItem id={id} onChange={onChange} isOpen={isOpen} borderColor="black">
      <AccordionHeader alignItems="flex-start" _expanded={{ bg: 'black' }}>
        <Box flex="1" textAlign="left" color={isOpen ? 'white' : 'black'}>
          <ReactMarkdown
            source={[
              String(question.no).padStart(2, '0'),
              isOpen ? question.question : question.keyword
            ].join('.')}
          />
        </Box>
        <Box color={isOpen ? 'white' : 'black'}>
          {isCorrect ? <RiCheckLine size="20px" /> : <CgClose size="20px" />}
        </Box>
      </AccordionHeader>
      <AccordionPanel pb="4">
        <QuestionAnswer question={question} isOpen={isOpen} selected={selected} />
      </AccordionPanel>
    </AccordionItem>
  )
}, [isOpen])

const allCategory = '全部題目'
const allIssue = '全部'

const AllQuestion = ({ result, tabsFixedPos }) => {
  const [openIndex, setOpenIndex] = useState()
  const [showFilter, setShowFilter] = useState()
  const [activeIssue, setIssue] = useState()
  const [filterFixedPos, setFixedPos] = useState()
  const [filterHeight, setFilterHeight] = useState()
  const filterRef = useRef()
  useEffect(() => {
    if (filterRef.current) {
      const { height } = filterRef.current.getBoundingClientRect()
      setFixedPos(height + tabsFixedPos)
      setFilterHeight(height)
    }
  }, [tabsFixedPos])
  useEffect(() => {
    setShowFilter(false)
  }, [activeIssue])
  return (
    <Box width="100%" style={{ paddingTop: filterHeight }}>
      <Box
        position={filterFixedPos ? 'fixed' : 'static'}
        style={{ top: filterFixedPos }}
        left="-2px"
        right="-2px"
        height="3em"
        zIndex="sticky"
        ref={filterRef}
      >
        <Button
          width="100%"
          height="100%"
          fontFamily="body"
          justifyContent="space-between"
          borderTop="2px solid black"
          borderBottom="2px solid black"
          px="2"
          rightIcon={GoTriangleDown}
          onClick={() => setShowFilter(!showFilter)}
        >
          目前顯示：{activeIssue ? activeIssue.join('｜') : allCategory}
        </Button>
      </Box>
      {showFilter ? (
        <Box>
          <FilterButton borderStyle="solid" onClick={() => setIssue(null)} rightIcon={activeIssue ? null : 'check'}>
            <Box.Inline>{allCategory}</Box.Inline>
          </FilterButton>
          <Accordion allowToggle defaultIndex={-1}>
            {categoriesIssue.map(({ category, issues }) => (
              <AccordionItem key={category} defaultIsOpen={activeIssue && activeIssue[0] === category}>
                <AccordionHeader borderBottom="3px solid black">
                  <Box flex="1" textAlign="left">
                    {category}
                  </Box>
                  <AccordionIcon />
                </AccordionHeader>
                <AccordionPanel p="0">
                  {[allIssue, ...issues].map(issue => (
                    <FilterButton
                      key={issue}
                      onClick={() => setIssue([category, issue])}
                      rightIcon={activeIssue && activeIssue[0] === category && activeIssue[1] === issue ? 'check' : null}
                    >{issue}</FilterButton>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      ) : (
        <Accordion
          allowToggle
          onChange={(i) => {
            ReactGA.event({
              category: '逐題詳解',
              action: questions[i].keyword,
            });
            setOpenIndex(i)
          }}
        >
          {questions.map((q, index) => {
            if (activeIssue && (q.category !== activeIssue[0] || (q.issue !== activeIssue[1] && activeIssue[1] !== allIssue))) return null
            return (
              <QuestionItem
                key={index}
                id={index}
                question={q}
                selected={get(result, ['result', 'q', q.no - 1])}
                isOpen={openIndex === index}
              />
            )
          })}
        </Accordion>
      )}
    </Box>
  );
};

export default AllQuestion;
