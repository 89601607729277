import React, { createElement, useEffect, useMemo, useRef, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Link, scroller } from 'react-scroll'
import ReactGA from 'react-ga'
import { useMeasure } from 'react-use';
import { FiList } from 'react-icons/fi'

import FullpageLoading from '../../components/FullpageLoading'
// import Container from '../../components/Container'
import Flex from '../../components/Flex'
import Box from '../../components/Box'
import Button from '../../components/Button'
import FullpageHeight from '../../components/FullpageHeight'
import theme, { responsive } from '../../components/ThemeProvider/theme';

import AllQuestion from './AllQuestion'
import Analyze from './Analyze';
// import Practice from './Practice';
import useResult from './useResult';
// import Support from './Support';
import useReading from './Practice/data';
import useResponsive from '../../contexts/mediaQuery/useResponsive';

const sections = [
  { key: 'analyze' , label: '答題分析', Comp: Analyze, to: 'analyze' },
  { key: 'practice' , label: '數養練習', Comp: Analyze, to: 'practice' },
  { key: 'supportUs' , label: '後續行動', Comp: Analyze, to: 'action' },
  { key: 'allQuestions' , label: '逐題詳解', Comp: AllQuestion, icon: FiList },
  { key: 'emotion',  to: 'emotion' },
]

// const renderThumbVertical = ({ style, ...props }) => {
//   const thumbStyle = {
//     backgroundColor: 'black'
//   };
//   return (
//     <div
//         style={{ ...style, ...thumbStyle }}
//         {...props}/>
//   );
// }

// const renderTrackVertical = props => <Box border="1px solid" {...props} />
// let viewed

const ResultPage = ({ match: { params: { key, tab } } }) => {
  // const [tabIndex, setTabIndex] =
  const [sectionId, setSection] = useState(() => Math.max(0, sections.findIndex(t => t.key === tab)), [tab])
  const [name, setScrollName] = useState()
  const [donate, setDonate] = useState(true)
  const { loaded, ...result } = useResult(key)
  // const history = useHistory()
  const readingData = useReading(result)
  const [measureRef, { height }] = useMeasure()
  const { isMobile } = useResponsive()
  const handleSetSection = (index) => {
    setSection(index)
    setTimeout(() => {
      const p = index === 3 ? `/${sections[index].key}` : ''
      window.history.replaceState(null, null, `/result/${key}${p}`)
      ReactGA.pageview(`/result${p}`);
    })
  }
  useEffect(() => {
    if (window && sectionId == 3) {
      window.scrollTo(0, 0)
    }
    // setTimeout(() => {
    //   scroller.scrollTo(name, {
    //     duration: 1000,
    //     delay: 100,
    //     smooth: true,
    //     offset: name === 'action' ? 600 : 350, // Scrolls to element + 50 pixels down the page
    //   })
    // })
  }, [sectionId])
  const handleSetActive = (to) => {
    if (to === 'emotion') {
      setScrollName(to)
    };
    const sid = sections.findIndex(s => s.to === to)
    if (sid > -1 && sid < 4) setSection(sid)
  }

  if (!loaded) return <FullpageLoading />
  if (!result) return <Redirect to="/" />
  return (
    <FullpageHeight pt={theme.headerHeight}>
      <Flex alignItems="stretch" position="fixed" top={theme.headerHeight} left="0" right="0" zIndex={999} ref={measureRef}>
        {sections.map(({ label, to, icon }, i) =>
          <Flex
            flexDir="column"
            justifyContent="center"
            width={label ? 1 / 4 : 0}
            borderLeft={i ? '1px solid' : 'none'}
            borderBottom="1px solid"
            textAlign="center"
            cursor="pointer"
            bg={i == sectionId ? 'black' : 'white'}
            key={label}
          >
            {createElement(Button, {
              bg: i == sectionId ? 'black' : 'white',
              width: '100%',
              fontFamily: "heading",
              fontSize: responsive('0.875em', '1em'),
              px: "2",
              py: "0.625rem",
              color: i == sectionId ? 'white' : 'black',
              border: 'none',
              leftIcon: icon,
              as: to && ((p) => (
                <Link
                  to={to}
                  smooth
                  spy
                  onSetActive={handleSetActive}
                  offset={i === 4 ? -200 : isMobile ? 210 : 100}
                  {...p}
                />
              )),
              // _selected={{
              //   bg: 'black',
              //   color: 'white',
              // }}
              onClick: () => {
                if (to) {
                  handleSetSection(i)
                  setTimeout(() => setScrollName(to))
                  return
                }
                handleSetSection(i)
                if (label) {
                  ReactGA.event({
                    category: '結果Header',
                    action: label,
                  })
                }
              }}
            , label)}
          </Flex>
        )}
      </Flex>
      <Box mx="auto" maxWidth={responsive('100%', '35em')} height="100%" style={{ paddingTop: height }}>
        {createElement(sections[sectionId].Comp, {
          result,
          readingData,
          tabsFixedPos: height,
          setDonate,
          name,
          donate,
        })}
      </Box>
    </FullpageHeight>
  );
};

export default ResultPage;
