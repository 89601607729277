import React from 'react'

import Box from '../../../components/Box'
import Text from '../../../components/Text'
import Link from '../../../components/Link'
import Heading from '../../../components/Heading'
import Container from '../../../components/Container'
import theme from '../../../components/ThemeProvider/theme'

const content = [
  '喜歡研究數據，但更愛將數據轉譯成有趣故事和體驗的團隊。工作習性是埋首於資料庫和資訊堆中，再混進人群裡思索溝通策略。',
  '因為工作，我們長期看了許多政府報告，也從中發現許多重要的線索。但我們也發現，民眾對於數據的不了解，讓我們難以在重要議題上對話。',
  '因此，從2019年我們開始了「臺灣發生什麼數」的社會參與計畫，期望能讓更多人一起從數據開啟對話、練習自己的數據識讀能力！',
]

const About = () => {
  return (
    <Container pt={theme.headerHeight}>
      <Box py="1.75em" px="2.875em">
        <Heading textAlign="center" borderBottom="1px solid" pb="1rem" fontSize="1.375em">Re-lab資訊設計顧問公司</Heading>
        {content.map((text, i) => <Text my="1em" key={i}>{text}</Text>)}
        <Text>了解更多「臺灣發生什麼數」計畫：<Link.Underline href="//www.redesigninfo.cc">www.redesigninfo.cc</Link.Underline></Text>
        <Text>訂閱粉專，在社群上一起玩數據：<Link.Underline href="//www.facebook.com/RedesignInfo">www.facebook.com/RedesignInfo</Link.Underline></Text>
        <Text>看看我們做了哪些作品：<Link.Underline href="//www.relab.cc">www.relab.cc</Link.Underline></Text>
      </Box>
    </Container>
  )
}

export default About
