import React, { useState, useMemo } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
} from "@chakra-ui/core";
import get from 'lodash/get'
import { RiCheckLine } from 'react-icons/ri'
import { CgClose } from 'react-icons/cg'
import ReactMarkdown  from 'react-markdown'

import Box from '../../../components/Box'
import Text from '../../../components/Text'
import QuestionAnswer from './QuestionAnswer';

const QuestionItem = ({ question, isOpen, selected, id, onChange }) => useMemo(() => {
  const isCorrect = selected == question.answer
  return (
    <AccordionItem id={id} onChange={onChange} isOpen={isOpen} borderColor="black">
      <AccordionHeader alignItems="flex-start" _expanded={{ bg: 'black' }}>
        <Box flex="1" textAlign="left" color={isOpen ? 'white' : 'black'}>
          <ReactMarkdown
            source={[
              String(question.no).padStart(2, '0'),
              isOpen ? question.question : question.keyword
            ].join('.')}
          />
        </Box>
        <Box color={isOpen ? 'white' : 'black'}>
          {isCorrect ? <RiCheckLine size="20px" /> : <CgClose size="20px" />}
        </Box>
      </AccordionHeader>
      <AccordionPanel pb="4">
        <QuestionAnswer question={question} isOpen={isOpen} selected={selected} />
      </AccordionPanel>
    </AccordionItem>
  )
}, [isOpen])

const AllQuestion = ({ result, isActive, questions }) => {
  const [openIndex, setOpenIndex] = useState()
  return (
    <Box mt="2em">
      <Text fontSize="1.5em" fontWeight="700">逐題詳解</Text>
      <Accordion allowToggle onChange={setOpenIndex}>
        {questions.map((q, index) => {
          return (
            <QuestionItem
              key={index}
              id={index}
              question={q}
              selected={get(result, [q.no - 1])}
              isOpen={isActive && openIndex === index}
            />
          )
        })}
      </Accordion>
    </Box>
  );
};

export default AllQuestion;
