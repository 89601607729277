import React from 'react';
import { withWindowSize } from 'libreact/modules/WindowSizeSensor';

import Box from './Box'
import theme from './ThemeProvider/theme';

const Fullpage = ({ children, windowSize, withHeader, ...props }) => (
  <Box style={{ height: withHeader ? `calc(${windowSize.height}px - ${theme.headerHeight})` : windowSize.height }} {...props}>
    {children}
  </Box>
)

export default withWindowSize(Fullpage);
