import React, { useEffect } from 'react'
import { useLocalStorage } from 'react-use'

import FullpageHeight from '../components/FullpageVerticalCenter'
import Box from '../components/Box'
import Flex from '../components/Flex'
import Heading from '../components/Heading'
import Text from '../components/Text'
import Image from '../components/Image'
import BottomSnapButton from '../components/BottomSnapButton'
import { responsive } from '../components/ThemeProvider/theme';
import LabelText from '../components/LabelText'

import useUserCount from '../contexts/user/useUserCount'
import useResponsive from '../contexts/mediaQuery/useResponsive';

import cover from './cover.png'
import useIsLarge from '../contexts/largeFont/useIsLarge'

const HomePage = ({ ver = '', large = 0 }) => {
  const { isMobile } = useResponsive()
  const userCount = useUserCount()
  useLocalStorage('TW_COG_VER', ver)
  const [isLarge, setIsLarge] = useIsLarge()
  useEffect(() => {
    setIsLarge(large)
  }, [large])
  const count = userCount ? String(userCount).padStart(4, '0') : '????'
  const content = (
    <Box px={responsive('4', '6')} py={responsive('1.25em', '12')} textAlign={responsive('left', 'right')}>
      <Heading fontWeight={700} fontSize={responsive('1em', '1.75em')} lineHeight="tall">
        你是第{count}位測驗填答者<br />
        用<Text.PingFang as="span" fontSize="1em">10</Text.PingFang>分鐘的時間，一起看見你不知道的臺灣
      </Heading>
    </Box>
  )

  const action = <BottomSnapButton textAlign={responsive('', 'right')} to="/introduction">點擊進入測驗</BottomSnapButton>
  return (
    <Box mx="auto" maxWidth="76em">
      <FullpageHeight>
        <Box width="100%" pt="4em">
          <Flex justifyContent="center" flexDirection={responsive('column-reverse', 'row')} alignItems="center">
            <Box px="1" mt={responsive('-3em', 0)}>
              <Box px={responsive('10%', 0)}>
                <Image src={cover} />
              </Box>
              {isMobile && content}
            </Box>
            <Box width={responsive('100%', isLarge ? '55%' : 1 / 2)} px="2" >
              <Box
                textAlign="right"
                borderBottom={responsive('none', '2px solid')}
                pb={responsive(isLarge ? '1em' : 0, '2em')}
              >
                <LabelText>測測你與臺灣的距離</LabelText>
                <Text mt="0.75rem" lineHeight="1.2" fontWeight="900" fontSize={responsive(isLarge ? '2.375em' : '2.75em', '5em')}>臺灣認知{isMobile && !isLarge && <br />}大考驗</Text>
                {ver ? <Text fontWeight="700" fontSize="1.5em">({ver})</Text> : ''}
              </Box>
              {!isMobile && (
                <>
                {content}
                {action}
                </>
              )}
            </Box>
          </Flex>

        </Box>
      </FullpageHeight>
      {isMobile && action}
    </Box>
  )
}

export default HomePage
