import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/core'
import { MdClose } from 'react-icons/md'

import Box from './Box'
import Button from './Button'
import { responsive } from './ThemeProvider/theme'
// import useResponsive from '../contexts/mediaQuery/useResponsive'

const ModalModule = ({
  close,
  open,
  children,
  closeOnOverlayClick,
  initialFocusRef,
  scrollBehavior,
  size = responsive('90%', '50%'),
  ...props
}) => {
  // const { isMobile } = useResponsive()
  const handleClose = () => {
    close()
    document.body.style.overflow = ''
  }
  return (
    <Modal
      isCentered
      size={size}
      isOpen={open}
      onClose={handleClose}
      closeOnOverlayClick={closeOnOverlayClick}
      initialFocusRef={initialFocusRef}
      scrollBehavior={scrollBehavior}
    >
      <ModalOverlay bg="white" opacity="0.8" />
      <ModalContent py="3em" px="6" border="3px solid" borderRadius="1.5em" {...props}>
        <Box position="absolute" top="-3px" right="-3px">
          <Button
            leftIcon={MdClose}
            border="3px solid"
            borderRadius="0 1.5em 0 1.5em"
            variant="outline"
            variantColor="black"
            bg="white"
            onClick={handleClose}
          >
            關閉視窗
          </Button>
        </Box>
        {children}
      </ModalContent>
    </Modal>
  )
}

export default ModalModule
