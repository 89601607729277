import React, { forwardRef } from 'react'
import {
  Input,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/core";
import { Field } from 'formik'

const ReInput = forwardRef((props, ref) => (
  <Input
    borderRadius="md"
    borderColor="black"
    focusBorderColor="yellow.600"
    {...props}
    ref={ref}
  />
))

export default ReInput

ReInput.Field = forwardRef(({ name, ...props }, ref) => (
  <Field name={name}>
    {({ field }) => <ReInput {...field} {...props} ref={ref} />}
  </Field>
))

ReInput.Number = (props) => (
  <NumberInput>
    <NumberInputField
      borderRadius="md"
      borderColor="primary"
      focusBorderColor="yellow.600"
      {...props}
    />
  </NumberInput>
)
