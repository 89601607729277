import React, { useState } from 'react';
import URLSearchParams from '@ungap/url-search-params'

import Context from './context'
import hookWithLocalStorage from '../../utils/hookWithLocalStorage'

const Provider = ({ children }) => {
  const [search] = hookWithLocalStorage('TW_COG_PARAMS', window.location.search, false, true)(useState)
  const params = new URLSearchParams(search)
  return (
    <Context.Provider value={params}>
      {children}
    </Context.Provider>
  )
}

export default Provider;
