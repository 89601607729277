import React from 'react'

import Box from './Box'
import { responsive } from './ThemeProvider/theme'

const LabelText = (props) => {
  return (
    <Box.Inline
      px={responsive('1rem', '2.375rem')}
      py="0.25em"
      fontSize={responsive('1em', '2em')}
      bg="black"
      rounded="full"
      color="white"
      {...props}
    />
  )
}

export default LabelText
