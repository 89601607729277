import { groupBy, sampleSize } from 'lodash'
import React, { useMemo } from 'react'
import ReactGA from 'react-ga'

import Slider from '../../../components/Slider'
import Box from '../../../components/Box'

import questions from '../../QuestionPage/data'
import QuestionAnswer from '../QuestionAnswer'
import { responsive } from '../../../components/ThemeProvider/theme'
import useIsLarge from '../../../contexts/largeFont/useIsLarge'

const AnswerSlider = ({ biasScores, result, showQ, isActive, positive = true }) => {
  const items = useMemo(() => {
    const bias = groupBy(questions.map((question, i) => ({
      question,
      selected: result.q[i],
      bias: biasScores[i],
    })), 'bias')
    let sampled = sampleSize(bias[positive ? '-2' : '2'], 5)
    if (sampled.length < 5) {
      sampled = sampled.concat(sampleSize(bias[positive ? '-1' : '1'], 5 - sampled.length))
    }
    return sampled
  }, [biasScores, positive, result.q])
  const [isLarge] = useIsLarge()

  return (
    <Box px={isLarge && 4}>
      <Slider
        beforeChange={(f, t) => {
          ReactGA.event({
            category: `${positive ? '樂觀' : '悲觀'}圖表`,
            action: t + 1,
          });
        }}
      >
        {(currentIndex) => items.map((item, i) => (
          <Box key={i} py="4" fontSize={responsive('0.875em', '1em')}>
            <QuestionAnswer showQ={showQ} {...item} isOpen={currentIndex === i} />
          </Box>
        ))}
      </Slider>
    </Box>
  )
}

export default AnswerSlider
