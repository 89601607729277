import React from 'react'

import Container from '../components/Container'
import Image from '../components/Image'
import Box from '../components/Box'
import Text from '../components/Text'
import LabelText from '../components/LabelText'
import Link from '../components/Link'

import introDoor from './intro-door-man.svg'
import { responsive } from '../components/ThemeProvider/theme'

const Success = () => {
  return (
    <Container.Fullpage pt="6" px="6" maxWidth="35em" textAlign="center">
      <Box.AbsCenter width="80%">
        <LabelText mb="8" px="3em" fontSize={responsive('1em', '1.5em')}>預約成功！！</LabelText>
        <Text>
        我們已收到你的申請
測驗重新上線後將於第一時間通知你<br />
想追蹤計劃最新進展請密切鎖定<Link href="https://www.facebook.com/RedesignInfo" color="blue.800">資訊改造實驗室</Link><br />
        </Text>
        <Box mx="auto" maxWidth="10em">
          <Image mt="10" src={introDoor} />
        </Box>
      </Box.AbsCenter>
    </Container.Fullpage>
  )
}

export default Success
