import logoPl from './logos/exercise.png'
import logoGi from './logos/giloo.png'
import logoEs from './logos/elite.png'
import logoCo from './logos/cofact.png'
import logoPs from './logos/ps.png'
// import logoRe from './logos/logo-re.png'
import step1 from './step1.png'
import step2 from './step2.png'
import step3 from './step3.png'

export const reasons = {
  scoreHigh: {
    text: '因為你在【<%= field %>】的題目中表現較佳',
    steplogo: step3,
  },
  scoreLow: {
    text: '因為你在【<%= field %>】的認知落差最大。',
    steplogo: step2,
  },
  negative: {
    text: '因為你對【<%= field %>】的議題很悲觀',
    steplogo: step1,
  },
  first: {
    text: '因為你第一個選擇的是【<%= field %>】',
    steplogo: step2,
  },
  duration: {
    text: '因為你在【<%= field %>】花了最長的時間作答',
    steplogo: step1,
  },
}

export const config = {
  law: {
    desc: '所以我們和<logo>為你選了這篇文章，帶你用思辨的方式看看其他可能性',
    by: '法律白話文',
    to: '文章',
    heading: '這個法律真有數',
    logo: logoPl,
    logoWidth: '6.625em',
    // attr: '<%= by %> 推薦這篇文章，聽聽他們有趣的法律解釋！'
  },
  doc: {
    desc: '所以我們和<logo>共同推薦你這部紀錄片，從不同角度重新認識現況',
    by: 'Giloo',
    to: '紀錄片',
    heading: '真實感受議題現場',
    // attr: '<%= by %> 免費請你看這部電影，找個朋友和你一起看電影吧！',
    note: '*註冊會員後即可免費觀賞本片30天！兌換期限到2021/12/31，數量有限，換完為止！',
    logo: logoGi,
    logoWidth: '3.375em',
  },
  books: {
    desc: '所以我們和<logo>共同推薦你這本書，從閱讀更全面暸解這個領域',
    by: '誠品',
    to: '書籍',
    heading: '打開知識的一本',
    // attr: '<%= by %> 推薦這本書，點開試閱連結找找看線索！',
    logo: logoEs,
    logoWidth: '4.5em',
  },
  fake: {
    desc: '所以我們和<logo>為你選了這篇假新聞，試著推理看看，提升你的資訊判斷力',
    by: 'Cofacts',
    to: '假新聞',
    heading: '從假新聞認識真相',
    // attr: '點開 <%= by %> 的查證連結，即可看答案！',
    logo: logoCo,
    logoWidth: '5.25em',
  },
  sci: {
    desc: '所以我們和<logo>為你選了這篇文章，帶你探索更多有趣的觀點',
    by: '泛科學',
    to: '文章',
    heading: '你有所不知的科學',
    // attr: '<%= by %> 推薦了這篇科普觀點，找找看有哪些證據！',
    logo: logoPs,
    logoWidth: '2.25em',
  }
}

const newList = [
  'books',
  'doc',
  'law',
  'sci',
  'fake',
].map(key => ({ ...config[key], key }))

export default newList
