import React from 'react'

import Box from '../../../components/Box'
import Text from '../../../components/Text'
import Flex from '../../../components/Flex'
import Image from '../../../components/Image'
import { responsive } from '../../../components/ThemeProvider/theme';
import icon from './icon.png'

const cases = [
    '製作1份重要事實測驗',
    '改造3份各部會重要報告',
    '設計1組數據素養教材'
]

const ToDo = () => {
  return (
    <Box mt="0.25em">
      {cases.map((title, k) => (
        <Flex alignItems="center" p="1em" key={k}>
          <Box width="2.5em" mx="1.25em">
            <Image src={icon} />
          </Box>
          <Box>
            <Text fontSize={responsive('1em', '1.25em')}>{title}</Text>
          </Box>
        </Flex>
      ))}
    </Box>
  )
}

export default ToDo
