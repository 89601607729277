import React, { useMemo } from 'react'
import { useSessionStorage } from 'react-use'

// import Box from '../../../components/Box'
import Text from '../../../components/Text'
import Flex from '../../../components/Flex'
import Container from '../../../components/Container';
import Heading from '../../../components/Heading'
// import FullpageLoading from '../../../components/FullpageLoading'

// import useQuestions from '../QuestionPage/useQuestions';
// import Distance from '../../ResultPage/Analyze/Distance'
import { responsive } from '../../../components/ThemeProvider/theme'
import AllQuestion from './AllQuestion'
import questions from '../QuestionPage/data'

const Result = () => {
  const [result] = useSessionStorage('TPE_COG', [])

  // const { questions, loading } = useQuestions()
  const correctNum = useMemo(() => questions.filter((d, i) =>
    d.answer == result[i]
  ).length , [result])

  // if (loading) return <FullpageLoading />
  return (
    <Container py="1em">
      <Heading mt="0.5em" as="h1" fontSize="2.25em" textAlign="center">你的答題結果</Heading>
      <Flex justifyContent="center" alignItems="flex-end" my="2em">
        <Text lineHeight="1" fontSize={responsive('1.5em', '3em')}>答對題數 {correctNum}</Text>
        <Text lineHeight="1" fontSize={responsive('1.25em', '2.5em')} mx="0.5em">/</Text>
        <Text lineHeight="1" fontSize={responsive('1em', '2em')}>{questions.length} <Text.Inline fontSize={responsive('0.875em', '1.5rem')}>(總題數)</Text.Inline></Text>
      </Flex>
      {/* <Box px="1.5em">
        <Distance totalScore={correctNum * 100 / questions.length} visible />
      </Box> */}
      <AllQuestion result={result} questions={questions} />
    </Container>
  )
}

export default Result
