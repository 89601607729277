import React, { Fragment } from 'react';
import { ThemeProvider, CSSReset } from '@chakra-ui/core'
import { Global } from '@emotion/core'

import globalStyles from './global-styles';
import WebFont from '../../webfont'
import theme from './theme';
import useIsLarge from '../../contexts/largeFont/useIsLarge';

const Provider = ({ children }) => {
  const [isLarge] = useIsLarge()
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <CSSReset />
        <WebFont />
        <Global styles={globalStyles} />
        <Global styles={{
          body: {
            fontSize: isLarge ? 20 : 16,
          }
        }} />
        {children}
      </Fragment>
    </ThemeProvider>
  )
}


export default Provider
