import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Observer from '@researchgate/react-intersection-observer';

export default class ViewableMonitor extends Component {
  static propTypes = {
    as: PropTypes.node,
    children: PropTypes.func.isRequired,
  };

  static defaultProps = {
    as: 'div',
    rootMargin: "0% 0% -35%",
  };

  state = {
    isIntersecting: false,
  };

  handleChange = ({ isIntersecting }, unobserve) => {
    if (this.props.once && isIntersecting) {
      unobserve()
    }
    this.setState({ isIntersecting });
  }

  render() {
    const { as: Tag, children, once, ...rest } = this.props;

    return (
      <Observer {...rest} onChange={this.handleChange}>
        <Tag>{children(this.state.isIntersecting)}</Tag>
      </Observer>
    );
  }
}
