import React from 'react'
import { Heading } from "@chakra-ui/core";

import Box from '../../../components/Box'
import Container from '../../../components/Container'
import Image from '../../../components/Image'

import support from './support.png'

import ToDo from './ToDo';

const Thanks = ({ location }) => {
  // console.log(location.search)
  return (
    <Container px="8" py="4" maxWidth="50em" mx="auto">
      <Box width="35%" mx="auto" mb="1em">
        <Image src={support} />
      </Box>
      <Heading my="1em" fontSize="1.75em">感謝贊助！<br />我們離這些目標又更進一步了！</Heading>
      <ToDo />
    </Container>
  )
}

export default Thanks
