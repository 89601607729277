import React, { forwardRef } from 'react'
import { Box } from "@chakra-ui/core";
import styled from '@emotion/styled'
import themeGet from '@styled-system/theme-get'

export default Box

Box.Inline = forwardRef((props, ref) => <Box as="span" display="inline-block" verticalAlign="middle" ref={ref} {...props} />);
Box.Absolute = forwardRef((props, ref) => <Box position="absolute" ref={ref} {...props} />);
Box.FullAbs = forwardRef((props, ref) => <Box.Absolute top="0" left="0" right="0" bottom="0" ref={ref} {...props} />);
Box.AbsCenter = forwardRef((props, ref) => <Box.Absolute top="50%" left="50%" transform="translate(-50%, -50%)" ref={ref} {...props} />);
Box.Relative = forwardRef((props, ref) => <Box position="relative" ref={ref} {...props} />);
Box.Fixed = forwardRef((props, ref) => <Box position="fixed" ref={ref} {...props} />);
Box.Invisible = (props) => <Box.Absolute width="0" height="0" overflow="hidden" opacity="0" {...props} />;

const getBorder = (props) => `
linear-gradient(to right, ${themeGet(`colors.${props.borderColor}`)(props)} 50%, rgba(255, 255, 255, 0) 0%),
linear-gradient(${themeGet(`colors.${props.borderColor}`)(props)} 50%, rgba(255, 255, 255, 0) 0%),
linear-gradient(to right, ${themeGet(`colors.${props.borderColor}`)(props)} 50%, rgba(255, 255, 255, 0) 0%),
linear-gradient(${themeGet(`colors.${props.borderColor}`)(props)} 50%, rgba(255, 255, 255, 0) 0%)
`

Box.Dash = styled(Box)`
  background: ${getBorder};
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 16px 1px, 1px 16px;
`

Box.Dash.defaultProps = {
  p: '3',
  borderColor: 'gray.400',
  borderRadius: 'lg',
}

Box.RightDash = styled(Box)`
  background: linear-gradient(${props => themeGet(`colors.${props.borderColor}`)(props)} 50%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-repeat: repeat-y;
  background-size: 1px 16px;
`
Box.RightDash.defaultProps = {
  borderColor: 'gray.400',
}

Box.BottomDash = styled(Box)`
  background: linear-gradient(to right, ${props => themeGet(`colors.${props.borderColor}`)(props)} 50%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: ${props => props.backgroundSize};
`
Box.BottomDash.defaultProps = {
  borderColor: 'gray.400',
  backgroundSize: '16px 1px',
}

