import React, { createElement } from 'react';
import { Field } from 'formik'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/core";

import get from 'lodash/get'

import Input from './Input'
import Textarea from './Textarea'
import Select from './Select'

const renderer = {
  select: Select,
  textarea: Textarea,
}

const ReFormControl = ({
  name,
  value,
  helper,
  label,
  type,
  placeholder,
  isRequired,
  isDisabled,
  isReadOnly,
  displayError,
  options,
  onKeyUp,
  ...props
}) => {
  return (
    <Field name={name} type={type} value={value}>
      {({ field, meta }) => (
        <FormControl isInvalid={Boolean(meta.touched && meta.error)} {...props}>
          {label && <FormLabel htmlFor={name} isRequired={isRequired}>{label}</FormLabel>}
          {createElement(get(renderer, type, Input), {
            type,
            placeholder,
            options,
            'aria-describedby': helper ? `${name}-helper-text` : '',
            onKeyUp,
            border: '2px solid',
            borderRadius: '0.875em',
            ...field
          })}
          {meta.touched && meta.error && (isRequired || displayError) ? (
            <FormErrorMessage>{meta.error}</FormErrorMessage>
          ) : (
            helper && (
              <FormHelperText id={`${name}-helper-text`}>
                {helper}
              </FormHelperText>
            )
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default ReFormControl;
