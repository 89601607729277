import React, { useMemo } from 'react'
import { sample, map } from 'lodash'

import Box from '../../../components/Box'
import Text from '../../../components/Text'
import Image from '../../../components/Image'

import Radar from './Radar'
import { config } from '../Practice/read'
import dash from './dash.svg'

const CategoryRadar = ({ categoryScores, orders, visible, readingData }) => {
  const data = useMemo(() => map(
    categoryScores, (value, key) => (
      { x: key, y: visible ? (10 - value.score) * 10 : 0 }
    )), [categoryScores, visible])
  const field = useMemo(() => sample(orders.score[0]), [orders])
  const reading = useMemo(() => Object.keys(readingData).map(key => ({
    ...readingData[key],
    ...config[key],
  })).find(r => r.field === field), [readingData, field])
  const avg = useMemo(() => 10 * Object.values(categoryScores).reduce((all, { score }, i, { length }) => all + (10 - score) / length, 0), [categoryScores])
  const paddings = [15, 25, 40, 40, 35, 25, 35, 40, 40, 35]
  return (
    <Box mt="1em">
      <Box textAlign="right">
        <Text><Image display="inline" src={dash} width="2.5em" />自己的平均答錯率</Text>
      </Box>
      <Radar
        values={data}
        axes={Object.keys(categoryScores).map((label, i) => ({ label, padding: paddings[i] }))}
        avg={visible ? avg : 0}
      />
      {/* {reading && (
        <Text>
          你在【{reading.field}】領域中，又以【{reading.issue}】課題的認知偏誤最大<br />
          到專屬的數養練習看看，我們和{reading.by}共同推薦你關注這件事的方法！
        </Text>
      )} */}
    </Box>
  )
}

export default CategoryRadar
