import React, { useEffect, useMemo, useRef, useState } from 'react'
import { scaleSqrt } from 'd3-scale'
import { format } from 'd3-format'
import {
  forceSimulation,
  forceX,
  forceY,
  forceCollide,
  forceLink,
  forceCenter,
  forceManyBody,
} from 'd3-force'
import { select } from 'd3-selection'
import { transition } from 'd3-transition'
import { xml } from 'd3-fetch'
import { drag } from 'd3-drag'
import max from 'lodash/max'
import maxBy from 'lodash/maxBy'
import sum from 'lodash/sum'
import map from 'lodash/map'
import { withSize } from 'react-sizeme'
import styled from '@emotion/styled'
import Bowser from 'bowser';

import theme, { responsive } from '../../../components/ThemeProvider/theme'
import Face, { faces } from '../../../components/Face'
import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Text from '../../../components/Text'
import useIsLarge from '../../../contexts/largeFont/useIsLarge'

const browser = Bowser.getParser(window.navigator.userAgent);
const iosVersion = String(browser.getOSName() === 'iOS' && browser.getOSVersion())
const goodAnimator = !iosVersion.startsWith('12') && !iosVersion.startsWith('13')
const facesText = [
  '樂觀領域',
  '中立領域',
  '悲觀領域'
]

const perFormat = format('.0%')

let node
let labels
let links

const StyledContainer = styled.div`
.face {
  pointer-events: none;
}
`

// function centroid(nodes) {
//   let x = 0;
//   let y = 0;
//   let z = 0;
//   for (const d of nodes) {
//     let k = d.r ** 2;
//     x += d.x * k;
//     y += d.y * k;
//     z += k;
//   }
//   return {x: x / z, y: y / z};
// }

// function forceCluster() {
//   const strength = 0.2;
//   let nodes;

//   function force(alpha) {
//     if (nodes) {
//       const centroids = rollup(nodes, centroid, d => d.group);
//       const l = alpha * strength;
//       for (const d of nodes) {
//         if (!d.isLable) {
//           const { x: cx, y: cy } = centroids.get(d.group);
//           d.vx -= (d.x - cx) * l;
//           d.vy -= (d.y - cy) * l;
//         }
//       }
//     }
//   }

//   force.initialize = _ => nodes = _;

//   return force;
// }

const handleDrag = simulation => {

  function dragstarted(event) {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    event.subject.fx = event.subject.x;
    event.subject.fy = event.subject.y;
  }

  function dragged(event) {
    event.subject.fx = event.x;
    event.subject.fy = event.y;
  }

  function dragended(event) {
    if (!event.active) simulation.alphaTarget(0);
    event.subject.fx = null;
    event.subject.fy = null;
  }

  return drag()
    .on("start", dragstarted)
    .on("drag", dragged)
    .on("end", dragended);
}

transition('fade').duration(750)

const CategoryEmotion = ({ categoryScores, size, visible }) => {
  const box = useRef()
  const [nodes, setNodes] = useState(map(categoryScores, (value, label) => {
    const maxValue = max(value.emotion)
    const isMax = value.emotion.map(v => v === maxValue)
    return {
      id: label,
      label,
      value: (maxValue / sum(value.emotion)) || 0,
      faceId: [0, 2, 1].find(i => isMax[i]),
    }
  }))
  const [isLarge] = useIsLarge()

  useEffect(() => {
    const width = Math.round(size.width)
    if (!width || !box.current) return
    const height = Math.round(size.width * 0.7)
    const rScale = scaleSqrt().domain([0, 1]).range([0, width * 0.1])
    box.current.innerHTML = ''

    const svg = select(box.current)
      .append("svg")
      .attr("viewBox", [0, 0, width, height]);

    const maxNode = maxBy(nodes, 'value')

    const dataNodes = nodes.reduce((all, node) => [
      ...all,
      {
        ...node,
        group: 'value',
      },
      {
        ...node,
        isLable: true,
        id: `label-${node.label}`,
        per: perFormat(node.value),
        value: 0.3,
        group: 'label',
      }], [{ id: 'root', value: 0.001 }])
    const dataLinks = dataNodes.reduce((all, node) => [...all, node.isLable ? {
      source: node.label,
      target: node.id,
      value: 0.001,
    } : {
      source: "root",
      target: node.id,
      value: 0.1,
    }], [])
    const fontSize = Math.round(width / 30)

    const simulation = forceSimulation(dataNodes)
      // .force("cluster", forceCluster())
      .force("center", forceCenter(width / 2, height / 2))
      .force("x", forceX(width * 0.5).strength(0.05))
      .force("y", forceY(height * 0.5).strength(0.8))
      .force("collision", forceCollide().radius(d => d.isLable ? fontSize * 2.25 : rScale(d.value)))
      .force("link", forceLink(dataLinks).id(d => d.id).strength(d => d.source.id === 'root' ? 2 : 1))
      .force("charge", forceManyBody().strength(d => d.isLable ? -100 : 0))
      .on('tick', tick)


    links = svg.append("g")
    .attr("stroke", "#000")
    .attr("stroke-opacity", 0.3)
    .selectAll("line")
    .data(dataLinks.filter(l => l.source.id !== 'root'))
    .join("line")
      .attr("stroke-width", 2)
      .attr('stroke-dasharray', 4)

    node = svg.append("g")
      .selectAll("g")
      .data(dataNodes.filter(d => !d.isLable && d.id !== 'root'))
      .join("g")

    if (goodAnimator) {
      node.call(handleDrag(simulation));
    }

    node.append('circle')
      .attr('title', d => d.id)
      .attr("r", d => rScale(d.value))
      .attr("fill", d => d.value === maxNode.value ? theme.colors.primary : 'white')
      .attr("stroke", 'black')
      .attr("stroke-width", 4)
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut)

    if (nodes[0].face) {
      node
        .append('g')
        .attr('class', 'face')
        .append(d => d.face)
    }

    labels = svg.append("g")
      .selectAll("text")
      .data(dataNodes.filter(d => d.isLable))
      .join("text")
      .attr('class', 'label')
      .attr('text-anchor', 'middle')
      .attr('font-size', fontSize)

    labels.append('tspan').text(d => d.per)
    labels.append('tspan')
      .attr('x', 0)
      .attr('dy', fontSize * 1.375)
      .text(d => d.label)


    function tick() {
      node
        .attr("transform", d => `translate(${d.x}, ${d.y})`)
        .select('.face')
        .attr('transform', d => {
          const r = rScale(d.value)
          return `translate(-${r}, -${r}), scale(${r * 2 / 106})`
        })

        labels.attr("transform", d => `translate(${d.x}, ${d.y})`)

        links
          .attr("x1", d => d.source.x)
          .attr("y1", d => d.source.y)
          .attr("x2", d => d.target.x)
          .attr("y2", d => d.target.y);
    }
    function handleMouseOver(event) {
      const active = event.target.getAttribute('title')
      node
        .filter(d => d.id !== active)
        .transition('fade')
        .attr('opacity', 0.2)
      links.filter(d => d.source.id !== active)
        .transition('fade')
        .attr('opacity', 0.2)

      labels.filter(d => d.label !== active)
        .transition('fade')
        .attr('opacity', 0.2)
    }

    function handleMouseOut() {
      node.transition('fade').attr('opacity', 1)
      links.transition('fade').attr('opacity', 1)
      labels.transition('fade').attr('opacity', 1)
    }
  }, [size, nodes, categoryScores])

  useEffect(() => {
    Promise.all(faces.map(src => xml(src))).then(srcs => {
      setNodes(nodes.map((d) => ({
        ...d,
        face: srcs[d.faceId].documentElement.children[0].cloneNode(true),
      })))
    })
  }, [])

  return (
    <>
      <Box mt="1em">
        <Flex alignItems="center">
          {facesText.reduce((all, text, i) => {
            const t = (
              <Flex alignItems="center" key={i} mr="0.5em" flexDirection={responsive(isLarge ? 'column' : 'row', 'row')}>
                <Box width="1.75em" mr="0.25em">
                  <Face n={i} />
                </Box>
                <Text mt="0.25em" fontSize="0.875em">{text}</Text>
              </Flex>
            )
            return [...all, i ? <Box key={`t-${i}`} pr="0.25em">/</Box> : null, t]
          }, [])}
        </Flex>
        <Text fontSize="0.875em" lineHeight="1.75" mt="1em">
          每個領域呈現的情緒為最容易影響你判斷的情緒<br />
          圓圈愈大，代表情緒影響你的判斷愈明顯<br />
          {goodAnimator && <Text.Inline fontSize="0.75em">*點擊不同領域，看看你受影響的比率有多高</Text.Inline>}
        </Text>
      </Box>
      {visible && <StyledContainer ref={box} />}
    </>
  )
}

export default withSize()(CategoryEmotion)
