import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'

import source from './source.json'

const questions = source.map((d, i) => ({
  category: d['領域'],
  subCategory: d['副領域'],
  issue: d['課題'],
  keyword: d['關鍵字'],
  question: d['題目'],
  options: ['選項A', '選項B', '選項C'].map(k => d[k]).filter(Boolean),
  answer: d['答案'] ? d['答案'].split(',').map(a => a.charCodeAt() - 65) : [-1],
  helper: d['說明'],
  type: d['答案類型'],
  chartType: d['答案類型'] === '趨勢' ? 'line' : 'bar',
  emotion: d['情緒'],
  no: i + 1,
}))

export default questions

export const allQuestionTypes = Object.keys(groupBy(questions, 'type'))

export const questionsByCategory = groupBy(questions, 'category')

export const questionsByIssue = mapValues(questionsByCategory, qs => groupBy(qs, 'issue'))

export const categories = Object.keys(questionsByCategory)

export const keywords = questions.map(q => q.keyword)

export const categoriesIssue = categories.map(category => ({
  category,
  issues: Object.keys(questionsByIssue[category]),
}))
