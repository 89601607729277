import health from './health.png'
import safety from './safety.png'
import nature from './nature.png'
import living from './living.png'
import social from './social.png'
import education from './education.png'
import industry from './industry.png'
import works from './works.png'
import economy from './economy.png'
import other from './other.png'

const data = [
  {
    key: 'health',
    name: '健康疾病',
    intro: '「你認為台灣人健康嗎？」',
    src: health
  },
  {
    key: 'safety',
    name: '公共安全',
    intro: '「你認為台灣安全嗎？」',
    src: safety
  },
  {
    key: 'nature',
    name: '自然環境',
    intro: '「你認為自然環境永續嗎？」',
    src: nature
  },
  {
    key: 'living',
    name: '居住環境',
    intro: '「你認為台灣適合居住嗎？」',
    src: living
  },
  {
    key: 'social',
    name: '社會照顧',
    intro: '「你認為社會上不同的人都能生存嗎？」',
    src: social
  },
  {
    key: 'education',
    name: '教育文化',
    intro: '「你認為教育優質嗎？」',
    src: education
  },
  {
    key: 'industry',
    name: '產業發展',
    intro: '「你認為台灣產業發展得如何？」',
    src: industry
  },
  {
    key: 'works',
    name: '勞動就業',
    intro: '「你認為在台灣工作好嗎？」',
    src: works
  },
  {
    key: 'economy',
    name: '經濟貿易',
    intro: '「你認為台灣的經濟繁榮嗎？」',
    src: economy
  },
  {
    key: 'other',
    name: '其他',
    intro: '「你認為台灣在不同的事情上好嗎？」',
    src: other
  },
]

export default data

export const stageByKey = data.reduce((all, stage) => {
  all[stage.key] = stage
  return all
}, {})
