import React from 'react'
import { Divider, Heading, Stack } from '@chakra-ui/core'
import { Link } from 'react-scroll'

import Text from '../../../components/Text'
import UiLink from '../../../components/Link'
import Button from '../../../components/Button'
import { responsive } from '../../../components/ThemeProvider/theme'

import useIsLarge from '../../../contexts/largeFont/useIsLarge'
import useResponsive from '../../../contexts/mediaQuery/useResponsive';

const SITE = 'https://exam.redesigninfo.cc'

const ShareButton = props => (
  <Button
    as={UiLink}
    border="3px solid"
    // borderRadius="1.5em"
    bg="white"
    px="1em"
    py="0.5em"
    fontSize="1.3755em"
    height="auto"
    {...props}
  />
)

const Conclusion = ({ result }) => {
  const [isLarge] = useIsLarge()
  const { isMobile } = useResponsive()
  return (
    <>
      <Heading mt="0.5em" as="h1" fontSize={responsive(isLarge ? '2em' : '2.25em', '2.25em')} textAlign="center">你的個人化數養提升內容</Heading>
      <Stack justifyContent="center" spacing="4">
        <Divider borderColor="black" borderWidth="0.125em" mt="4" />
        <Text.Bold fontSize="1.125rem">
          最重要的不是答對幾題<br/>
          而是看見自己與社會的認知差距
        </Text.Bold>
        <Text fontSize="0.875rem">支持我們推廣更多好數！<br />讓更多人有機會發現自己的認知落差</Text>
        <ShareButton as={p => <Link {...p} to="action" smooth offset={isMobile ? 210 : 100} />} bg="bgYellow">贊助計畫</ShareButton>
        <ShareButton href={`https://www.facebook.com/sharer/sharer.php?u=${SITE}${isLarge ? '/clear' : ''}?utm_source=result&utm_medium=facebook`}>分享測驗</ShareButton>
      </Stack>
    </>
  )
}

export default Conclusion
