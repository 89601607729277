import React, { useEffect, useMemo, useRef, useState } from 'react'

import { get, template } from 'lodash';
import ReactGA from 'react-ga'

import Box from '../../../components/Box'
import Text from '../../../components/Text'
import Carousel from '../../../components/Carousel'
import Image from '../../../components/Image'
import BackgroundImage from '../../../components/BackgroundImage'
import { responsive } from '../../../components/ThemeProvider/theme'

import magazine from './magazine.png'

import { config, reasons } from './read';
import logos from './logos/logos.png'
import loader from '../../../imageLoader';
import { Board } from '../../Introduction';

const imgRegex = /([^>]+)<logo>([^<]+)/

const PracticeContent = ({
  text,
  by,
  attr,
  desc,
  title,
  logoWidth,
  heading,
  url,
  to,
  field,
  img,
  issue,
  note,
  logo,
  steplogo
}) => (
  <>
    <Box width="6.25em" mx="auto" mb="1em">
      <Image src={steplogo} />
    </Box>
    {/* <Heading fontSize="1.75em" as="h3">{heading}</Heading> */}
    {/* <Divider borderColor="black" /> */}

    <Text.Bold whiteSpace="pre-wrap">{template(text)({ field })}</Text.Bold>
    <Text whiteSpace="pre-wrap">
      {imgRegex.exec(desc)[1]}
      <Box.Inline width={logoWidth} mx="0.25em">
        <Image src={logo} alt={by} title={by} />
      </Box.Inline>
      {imgRegex.exec(desc)[2]}
    </Text>
    {/* <Text>{template(attr)({ by })}</Text> */}
    {/* {note && <Text fontSize="0.875em" mt="0.5em">{note}</Text>} */}
    <Box mt="1em" textAlign="center" textDecoration="underline">
      <ReactGA.OutboundLink
        eventLabel={title}
        to={url}
        rel="noreferrer noopener"
      >
        {title}
      </ReactGA.OutboundLink>
    </Box>
    <Box pb="1.5em" borderBottom="1px solid" my="4">
      <ReactGA.OutboundLink to={url} eventLabel={title} rel="noreferrer noopener">
        <BackgroundImage backgroundSize="contain" ratio={630 / 1200} src={img} />
      </ReactGA.OutboundLink>
    </Box>
    {/* <Flex mt="4" justifyContent="center">
      <Button.BlackOutline mx="1" href={url}>前往{to}頁面</Button.BlackOutline>
    </Flex> */}
  </>
)

const Practice = ({ readingData }) => {
  const ref = useRef()
  useEffect(() => {
    loader(readingData.map(([, r]) => r && r.img))
  }, [readingData])
  return (
    <Box py="6">
      <Box px={responsive('10', '4')}>
        <Box px="20%" bg="white">
          <Image src={magazine} />
        </Box>
        <Box mb="8">
          <Box fontWeight="bold" borderBottom="1px solid" py="4">
            {/* <Text fontSize="1.5em" textAlign="center" letterSpacing="0.125rem">如何提升數據素養？</Text> */}
            {/* <Text fontSize="1.125em" textAlign="center">五道練習題，提升數據識讀力！</Text> */}
          </Box>
          <Text my="6">
            根據你的填答結果<br />
            我們歸納出幾個社會課題<br />
            <Text.Bold fontSize="1.25em" my="1rem">現況，可能和你想得不一樣</Text.Bold>
            接下來，<br />
            讓五位合作夥伴為你親選專屬內容，<br />
            開啟提升數養的入口
          </Text>
          <Text.Bold textAlign="center">由以下5位專業夥伴共同推薦</Text.Bold>
          <Box mx="auto" maxWidth="20em">
            <Image src={logos} />
          </Box>
        </Box>
      </Box>
      <Box pt="6" px={responsive(2, 0)} ref={ref}>
        <Carousel
          silderProps={{
            slidesToShow: 1,
            infinite: false
          }}
          beforeChange={(f, t) => {
            // console.log(read[t])
            ReactGA.event({
              category: '數養練習',
              action: `第${t + 1}題`,
            })
          }}
        >
          {readingData.map(([r, d], i) => (
            <Board title="根據你的填答結果，我們推薦你..." childPx="1.25em" key={i}>
              <PracticeContent {...d} {...config[d.key]} {...reasons[r]} />
            </Board>
          ))}
        </Carousel>
      </Box>
    </Box>
  )
}

export default Practice
