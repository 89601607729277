import React, { useState, useMemo, useEffect } from 'react';
import { useFirebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import get from 'lodash/get'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'

import Context from './context'
import hookWithLocalStorage from '../../utils/hookWithLocalStorage'
import useQs from '../qs/useQs'

let hasUser

const Provider = ({ children }) => {
  const qs = useQs()
  const [mail, setEmail] = hookWithLocalStorage('TW_COG_EMAIL', qs.get('email'), false, true)(useState)
  useFirebaseConnect([`users#orderByChild=email&equalTo=${mail}&limitToFirst=1`, 'userCount'])
  const users = useSelector(state => state.firebase.ordered.users)
  const user = useMemo(() => users && users.find(u => u.value.email === mail), [users, mail])
  const isLoading = !isLoaded(user)
  const userCount = useSelector(state => get(state.firebase.data, 'userCount', -1))
  hasUser = !isLoading && !isEmpty(user)
  useEffect(() => {
    if (user) {
      ReactGA.set({ userId: user.key })
    }
  }, [user])
  const handleSetEmail = (val) => new Promise((res) => {
    setEmail(val)
    const checker = () => setTimeout(() => {
      if (val) {
        if (hasUser ? val === user?.value?.email : !isLoading) {
          res()
        } else {
          checker()
        }
      } else {
        if (hasUser) {
          checker()
        } else {
          res()
        }
      }
    }, 200)
    checker()
  })
  return useMemo(() => (
    <Context.Provider
      value={{
        user: user || {},
        hasUser,
        setEmail: handleSetEmail,
        isLoading: mail && isLoading,
        userCount: hasUser ? user.value.no : (userCount + 1),
        answerKey: hasUser ? user.value.answerKey : '',
      }}
    >
      {children}
    </Context.Provider>
  ), [isLoading, user, children])
}

export default Provider;
