import { createContext } from 'react';

export default createContext({
  user: {},
  setEmail: () => {},
  isLoading: false,
  userCount: -1,
  hasUser: false,
  answerKey: '',
})
