import React from 'react'
import { Redirect } from 'react-router-dom'
import get from 'lodash/get'
import { useField } from 'formik'

import Container from '../../components/Container'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Image from '../../components/Image'
import Heading from '../../components/Heading'
import Text from '../../components/Text'
import BottomSnapButton from '../../components/BottomSnapButton'
import { responsive } from '../../components/ThemeProvider/theme';
import useResponsive from '../../contexts/mediaQuery/useResponsive'
import useIsLarge from '../../contexts/largeFont/useIsLarge'

import StageProgress from './StageProgress'
import getAnswerStats from '../QuestionPage/getAnswerStats'

import { stageByKey } from './data'

const StageIntro = ({ match: { params: { key } } }) => {
  const stage = stageByKey[key]
  const { stageProgress } = getAnswerStats()
  const { isMobile } = useResponsive()
  const [{ value }, , { setValue }] = useField({ name: `stageStart.${key}` })
  const [isLarge] = useIsLarge()
  if (!key) {
    return <Redirect to="/stage" />
  }
  return (
    <>
      <Container.Fullpage withHeader>
        <StageProgress />
        <Box as={!isMobile && Box.AbsCenter} width="full">
          <Flex pt={responsive(0, '6')} alignItems="center" flexDirection={responsive('column-reverse', 'row')}>
            {/* <Heading as="h4" fontSize="1.25em" my="2">台灣安全嗎？</Heading> */}
            {/* <Text fontSize="1.125em" my="2">{stage.intro}</Text> */}
            <Box flex={1} px={responsive('20%', '4em')} pb="4">
              <Image src={stage.src} />
            </Box>
            <Box width={responsive('auto', 1 / 2)} textAlign={responsive('center', 'left')}>
              <Heading fontSize={responsive('3.625em', '5.5em')} pt={responsive('10', 0)} pb="4">{stage.name}</Heading>
              {!isMobile && (
                <>
                  <Text color="lightGray" fontSize="2em" mb="6">接下來會有10道題目，<br />每題作答時間{isLarge ? 30 : 20}秒</Text>
                  <BottomSnapButton
                    to={`/stage/${key}/q/${get(stageProgress, key, 0) + 1}`}
                    onClick={() => !value && setValue(+new Date())}
                    textAlign="left"
                  >
                    點擊開始測驗
                  </BottomSnapButton>
                </>
              )}
            </Box>
          </Flex>
        </Box>
        {isMobile &&
          <>
            <Text textAlign="center" color="lightGray" fontSize="1.25em" mb="6">接下來會有10道題目，每題作答時間{isLarge ? 30 : 20}秒</Text>
            <BottomSnapButton
              to={`/stage/${key}/q/${get(stageProgress, key, 0) + 1}`}
              onClick={() => !value && setValue(+new Date())}
            >
              點擊開始測驗
            </BottomSnapButton>
          </>
        }
      </Container.Fullpage>
    </>
  )
}

export default StageIntro
