import React from 'react'
import { Select } from "@chakra-ui/core";

const ReSelect = ({ options, value, ...props }) => (
  <Select
    borderRadius="0.875em"
    border="2px solid"
    borderColor="black"
    focusBorderColor="black"
    placeholder="請選擇"
    {...props}
  >
    {options.map(val => (
      <option value={val} key={val} selected={val == value}>{val}</option>
    ))}
  </Select>
)

export default ReSelect
