import React from 'react';
import {
  Icon,
  Stack,
  AspectRatioBox,
} from "@chakra-ui/core";
import ReactMarkdown  from 'react-markdown'

import Box from '../../components/Box'
import Circle from '../../components/Circle'
import Flex from '../../components/Flex'
import QuestionChart from './QuestionChart';
import { responsive } from '../../components/ThemeProvider/theme';

const CircleIcon = ({ fontSize = '12px', bg = 'black', ml, verticalAlign = 'top', ...props }) => (
  <AspectRatioBox
    ratio={1}
    fontSize={fontSize}
    width="1.5em"
    display="inline-block"
    ml={ml}
    verticalAlign={verticalAlign}
  >
    <Box lineHeight="1.75" textAlign="center">
      <Icon {...props} />
    </Box>
  </AspectRatioBox>
)

const QuestionAnswer = ({ question, isOpen, showQ, selected }) => (
  <>
    {showQ && (
      <Flex mb="4" fontSize={responsive('1em', '1.125em')} alignItems="center">
        <Circle bg="bgYellow" width="2em" mr="0.5em" fontWeight="bold">Q</Circle>
        <Box flex={1}><ReactMarkdown source={question.question} /></Box>
      </Flex>
    )}
    <QuestionChart question={question} isOpen={isOpen} key={question.no} />
    <Stack isInline pt="4">
      {question.options.map((opt, i) => {
        const isSelected = i == selected
        const isAnswer = question.answer.some(a => a== i)
        return (
          <Box
            key={i}
            width="100%"
            textAlign="center"
            py="2"
            bg={isAnswer ? 'bgYellow' : isSelected ? 'gray.200' : 'white'}
            color="black"
            borderRadius="full"
            border={!isAnswer && !isSelected && '1px solid' }
            px="2"
          >
            {String.fromCharCode(65 + i)}. {opt}
            {isAnswer ? <CircleIcon ml="2" name="check" /> : (
              isSelected && <CircleIcon ml="2" name="close" />
            )}
          </Box>
        )
      })}
    </Stack>
  </>
)

export default QuestionAnswer
