import { useMemo } from 'react'
import { useField } from 'formik'
import mapValues from 'lodash/mapValues'
import get from 'lodash/get'
import size from 'lodash/size'

import { stageByKey } from '../StagePage/data'
import { questionsByCategory } from './data'

export default () => {
  const [{ value }] = useField({ name: 'q' })
  const stageClear = useMemo(() => mapValues(stageByKey,
    ({ name }) => questionsByCategory[name].every((q) => Boolean(get(value, q.no - 1)))
  ), [value])
  const stageProgress = useMemo(() => mapValues(stageByKey,
    ({ name }) => questionsByCategory[name].findIndex((q) => !Boolean(get(value, q.no - 1)))
  ), [value])
  // console.log(value)
  const clearCount = useMemo(() => Object.values(stageClear).filter(Boolean).length, [stageClear])
  const allClear = useMemo(() => size(stageByKey) === clearCount, [clearCount])
  const half = useMemo(() => (size(stageByKey) / 2) === clearCount, [clearCount])
  return {
    stageClear,
    stageProgress,
    clearCount,
    allClear,
    half,
  }
}
