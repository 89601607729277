import React, { forwardRef } from 'react';

import Box from './Box'
import Button from './Button'

import useResponsive from '../contexts/mediaQuery/useResponsive'

const BottomSnapButton = forwardRef(({ position, ...props }, ref) => {
  const { isMobile } = useResponsive()
  return (
    <>
      <Box pt="2.5em" />
      <Box position={position || (isMobile ? 'fixed' : 'static')} left="0" right="0" bottom="0" zIndex="docked" textAlign={props.textAlign || 'center'}>
        <Button
          width={(props.square || isMobile) ? '100%' : 'auto'}
          height="2.5em"
          fontSize="1.5rem"
          fontWeight="normal"
          letterSpacing="wider"
          px="8"
          borderRadius={(props.square || isMobile) ? 0 : '1.5em'}
          mb={isMobile ? 0 : '1em'}
          ref={ref}
          {...props}
        />
      </Box>
    </>
  );
});

export default BottomSnapButton;
