import { useState, useEffect, useMemo } from 'react'
import { tsv } from 'd3-fetch'
import {
  groupBy,
  mapValues,
  sample,
  shuffle,
  sampleSize,
  reduce,
  every,
  get,
  last,
  zip,
} from 'lodash'

import gilooImg from './giloo'
// import reporterImg from './reporter'
import lawImg from './law'

import books from './books.tsv'
import fake from './cofact.tsv'
import doc from './giloo.tsv'
import sci from './pansci.tsv'
import law from './law.tsv'
// import news from './news.tsv'
import cofact from './cofact@2x-100.jpg'
import { stageByKey } from '../../../StagePage/data'

const images = {
  doc: gilooImg,
  // news: reporterImg,
  law: lawImg,
}

const maskterKeys = {
  books: '主推書籍書名',
  fake: '假新聞內容',
  doc: '推薦的影片',
  sci: '文章',
  law: '文章',
  // news: '專題名稱',
}

const source = {
  books,
  fake,
  doc,
  sci,
  law,
  // news,
}

const formatData = (data, key) => {
  const masterKey = maskterKeys[key]
  return data.reduce((all, item, i) => {
    const followed = i
      ? mapValues(item, (v, k) =>
        // 若沒有值，就複製前一位的值
        !v && ![masterKey, 'url', '副領域'].some(kk => kk === k)
        ? all[i - 1][k]
        : v
      ) : item
    return all.concat(followed)
  }, [])
  // 濾掉沒有標題的課題
  .filter(item => item[masterKey])
  .map(item => Object.assign({
    title: item[masterKey],
    field: item['領域'],
    issue: item['課題'],
    url: item.url,
    img: key === 'fake' ? cofact : (item.img.startsWith('http') ? item.img : get(images, [key, item.img])),
  }, item.by ? { by: item.by } : null))
  .filter(item => item.url)
}

const useData = (url, key) => {
  const [data, setData] = useState()
  useEffect(() => {
    tsv(url).then((d) => {
      const parsed = formatData(d, key)
      // console.log(parsed)
      setData(groupBy(parsed, 'field'))
    })
  }, [])

  return data
}

const getReadData = (order, readingData, count = 2) => {
  let data = []
  // 從領域排名往下找，找到兩位為止
  for (let index = 0; index < order.length; index++) {
    const fields = order[index];
    const toConcat = shuffle(fields).map(field => mapValues(readingData, v => sample(v[field]))).filter(Boolean)
    const newLength = data.length + toConcat.length
    if (newLength > count) return data.concat(sampleSize(toConcat, count - data.length))
    data = data.concat(toConcat)
    if (newLength === count) return data
  }
  return data
}

const reasons = [
  'scoreHigh',
  'scoreLow',
  'negative',
  'first',
  'duration',
]

export default function useReading({ orders, categoryOrder }) {
  const readingData = mapValues(source, useData)
  const loaded = every(readingData, Boolean)
  const reading = useMemo(() => {
    if (!loaded || !orders) return []
    let keys
    let tries = 0
    const getReads = () => {
      keys = shuffle(Object.keys(source))
      const scoreHigh = getReadData(orders.score, readingData, 1)[0]
      const scoreLow = getReadData([...orders.score].reverse(), readingData, 1)[0]
      const negative = getReadData(orders.negative, readingData, 1)[0]
      const first = getReadData([[stageByKey[categoryOrder[0]].name]], readingData, 1)[0]
      const duration = getReadData(orders.duration, readingData, 1)[0]
      const read = [
        scoreHigh,
        scoreLow,
        negative,
        first,
        duration,
      ]
      return reasons.map((k, i) => {
        const key = keys[i]
        return { ...read[i][key], key }
      }, {})
    }
    let reads = getReads()
    while (!reads.every(Boolean) && tries < 10) {
      tries += 1
      reads = getReads()
    }
    return zip(reasons, reads)

  }, [categoryOrder, loaded, orders])
  return reading

  // return useMemo(() => {
  //   if (!loaded) return {}
  //   let keys
  //   let tries = 0
  //   const getReads = () => {
  //     // 打亂媒材的排序
  //     keys = shuffle(Object.keys(source))
  //     let i = -1

  //     return reduce(orders, (all, order) => {
  //       // 每個排名找出兩位
  //       const orderReadData = getReadData(order, readingData)
  //       i += 1
  //       const d = orderReadData.map((d, j) => {
  //         // 然後各找出一種媒材
  //         const key = keys[i * 2 + j]
  //         return d[key]
  //       })
  //       return [...all, ...d]
  //     }, [])
  //   }

  //   let reads = getReads()

  //   while (!reads.every(Boolean) && tries < 10) {
  //     tries += 1
  //     reads = getReads()
  //   }

  //   // 組回一個物件
  //   return keys.reduce((d, key, i) => {
  //     d[key] = reads[i]
  //     return d
  //   }, {})
  // }, [orders, loaded])
}
