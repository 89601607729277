import { isArray, isNil, isObject, mapValues, pickBy } from "lodash"
import sanitizeHtml from 'sanitize-html'

const noInvalidValue = val => val !== '' && !isNil(val)
const ensureValue = val => {
  if (isArray(val)) return val.map(d => noInvalidValue(d) ? d : '')
  if (isObject(val)) return pickBy(val, noInvalidValue)
  return val
}


const whitelist = {
  q: true,
  qDuration: true,
  stageStart: true,
  stageFinish: true,
}

const sanitizer = (values) => mapValues(pickBy(values, noInvalidValue), (value, key) => ensureValue(whitelist[key] ? value : sanitizeHtml(value)))

export default sanitizer

