import React, { useRef, useState } from 'react'
import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Box from './Box'
import ChevButton from './ChevButton';
import { responsive } from './ThemeProvider/theme';

const Slider = ({ children, beforeChange, ...props }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const sliderRef = useRef()
  return (
    <Box.Relative>
      <Slick
        dots
        beforeChange={(f, t) => {
          if (typeof beforeChange === 'function') beforeChange(f, t)
          setCurrentSlide(t)
        }}
        {...props}
        ref={sliderRef}
      >
        {typeof children === 'function' ? children(currentSlide) : children}
      </Slick>
      {[0, 1].map(next => (
        <Box.Absolute
          key={next}
          top="50%"
          left={!next && responsive('-1.5em', '-2.5em')}
          right={next && responsive('-1.5em', '-2.5em')}
          transform={`translateY(-50%)`}
        >
          <ChevButton
            onClick={() => sliderRef.current[next ? 'slickNext' : 'slickPrev']()}
            prev={!next}
            fontSize={responsive('1.25em', '1.875em')}
            minWidth="none"
            height="auto"
          />
        </Box.Absolute>
      ))}
    </Box.Relative>
  )
}

export default Slider
