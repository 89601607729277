import React from 'react'
import { Global, css } from '@emotion/core'

import woff from './creamfont.woff'
import ttf from './creamfont.ttf'

export default () => (
  <Global
    styles={css`
      @font-face {
      font-family: creamfont;
      src: local("creamfont-1.0-Regular"),
        url("${woff}"),
        url("${ttf}");
      font-weight: 400;
    }
    `}
  />
)
