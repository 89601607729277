const img = {
  '104291135_2622826004659757_2670072162124031333_o.jpg': require('./104291135_2622826004659757_2670072162124031333_o.jpg').default,
  '117743785_2661057570836600_6066866731925039938_n.jpg': require('./117743785_2661057570836600_6066866731925039938_n.jpg').default,
  '67180254_2343074712634889_6834477580045778944_o.jpg': require('./67180254_2343074712634889_6834477580045778944_o.jpg').default,
  '68544504_2363427817266245_1504068276857077760_o.png': require('./68544504_2363427817266245_1504068276857077760_o.png').default,
  '69691870_2376204255988601_2133121642434396160_o.png': require('./69691870_2376204255988601_2133121642434396160_o.png').default,
  '70819963_2384324355176591_6126970851486072832_o.png': require('./70819963_2384324355176591_6126970851486072832_o.png').default,
  '83237181_2499029220372770_5461371553352515584_o.jpg': require('./83237181_2499029220372770_5461371553352515584_o.jpg').default,
  '95769911_2578743379068020_3754315799167238144_o.jpg': require('./95769911_2578743379068020_3754315799167238144_o.jpg').default,
}

export default img
