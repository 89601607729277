import React, { useRef, useState, useCallback } from 'react';
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import nativeFirebase from 'firebase'
import { json } from 'd3-fetch'
import get from 'lodash/get'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
} from "@chakra-ui/core";

import Container from '../components/Container'
import Box from '../components/Box'
import Text from '../components/Text'
import FormControl from '../components/FormControl'
import BottomSnapButton from '../components/BottomSnapButton'
import Image from '../components/Image'
import { responsive } from '../components/ThemeProvider/theme';

import useUser from '../contexts/user/useUser'

// import introDoor from './intro-door-man.svg'
import useResponsive from '../contexts/mediaQuery/useResponsive';

let userMemo
let userAnswerMemo

const BeforeStart = () => {
  const { setFieldValue, setFieldError, errors, values } = useFormikContext()
  const btnRef = useRef()
  const [loading, setLoading] = useState()
  const { user, setEmail, hasUser } = useUser()
  const { isMobile } = useResponsive()
  useFirebaseConnect([hasUser && user.value.answerKey ? `answers/${user.value.answerKey}` : 'answers'])
  const userAnswer = useSelector(state => get(state.firebase.data, ['answers', hasUser ? user.value.answerKey : '']))
  const history = useHistory()
  userMemo = user
  userAnswerMemo = userAnswer
  const handleClick = useCallback(async () => {
    setLoading(true)
    try {
      const res = await json(process.env.NODE_ENV === 'production' ? '/requestValidationApp' : 'https://us-central1-tw-cognition.cloudfunctions.net/requestValidationAppLimiter', {
        method: 'POST',
        body: JSON.stringify({ email: values.email }),
        headers: {
          'content-type': 'application/json'
        },
      })
      if (res.error) {
        setFieldError('email', res.error)
      } else {
        await setEmail(values.email.toLowerCase())
        if (res.validated) {
          setTimeout(() => {
            history.push(userAnswerMemo && userAnswerMemo.completedAt ? `/result/${userMemo.value.answerKey}` : '/stage')
          })
        }
      }
    } catch (e) {
      console.error(e)
      setFieldError('email', e.message)
    }

    setLoading(false)
  }, [values.email, setEmail, history, setFieldError, setFieldValue])
  return (
    <Container.Fullpage pt="6" px="6" maxWidth="35em" textAlign="center">
      <Box as={isMobile ? 'div' : Box.AbsCenter}>
        <Box as={isMobile ?  Box.AbsCenter : 'div'} width={responsive('90%', '100%')}>
          <Text fontSize={responsive('1em', '1.25em')} fontWeight="bold">你已完成驗證</Text>
          <FormControl
            mt="2"
            mb="5"
            name="email"
            placeholder="email@example.com"
            isReadOnly={user.value}
            isRequired
            onKeyUp={e => e.keyCode === 13 && setTimeout(() => {
              if (btnRef.current) {
                btnRef.current.click()
              }
            })}
          />
          <Box pt={responsive('8', 0)} />
        </Box>
        <BottomSnapButton
          onClick={handleClick}
          isDisabled={!values.email || errors.email}
          isLoading={loading}
          ref={btnRef}
        >
          開始測驗
        </BottomSnapButton>
      </Box>
    </Container.Fullpage>
  );
};

export default BeforeStart;
