import React, { useState } from 'react';

import Context from './context'
import hookWithLocalStorage from '../../utils/hookWithLocalStorage'

const Provider = ({ children }) => {
  const [isLarge, setIsLarge] = hookWithLocalStorage('TW_COG_LARGE', 0)(useState)
  return (
    <Context.Provider value={[isLarge, setIsLarge]}>
      {children}
    </Context.Provider>
  )
}

export default Provider;
