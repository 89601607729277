import source from './source.json'

const questions = source.map((d, i) => ({
  question: d['題目'],
    options: ['選項A', '選項B', '選項C'].map(k => d[k]).filter(Boolean),
    answer: d['答案'] ? d['答案'].charCodeAt() - 65 : -1,
    no: i + 1,
    keyword: d['關鍵字'],
    type: d['類別'],
    chartType: d['類別'] === '趨勢' ? 'line' : 'bar',
}))

export default questions

// export const allQuestionTypes = Object.keys(groupBy(questions, 'type'))

// export const questionsByCategory = groupBy(questions, 'category')

// export const questionsByIssue = mapValues(questionsByCategory, qs => groupBy(qs, 'issue'))

// export const categories = Object.keys(questionsByCategory)

// export const keywords = questions.map(q => q.keyword)

// export const categoriesIssue = categories.map(category => ({
//   category,
//   issues: Object.keys(questionsByIssue[category]),
// }))
