import React, { useRef, useState, useCallback } from 'react';
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import nativeFirebase from 'firebase'
import get from 'lodash/get'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/core";
import { json } from 'd3-fetch'

import Container from '../components/Container'
import Box from '../components/Box'
import Text from '../components/Text'
import FormControl from '../components/FormControl'
import BottomSnapButton from '../components/BottomSnapButton'
import Image from '../components/Image'
import Button from '../components/Button'
import { responsive } from '../components/ThemeProvider/theme';

import useUser from '../contexts/user/useUser'

// import introDoor from './intro-door-man.svg'
import useResponsive from '../contexts/mediaQuery/useResponsive';
import useQs from '../contexts/qs/useQs';

let userMemo
let userAnswerMemo

const BeforeStart = () => {
  const { setFieldValue, setFieldError, errors, values } = useFormikContext()
  const btnRef = useRef()
  const qs = useQs()
  const [loading, setLoading] = useState()
  const { user, setEmail, hasUser } = useUser()
  const { isMobile } = useResponsive()
  const isVerified = hasUser && user.value.emailVerified
  const [isOpen, setIsOpen] = useState(hasUser && !user.value.emailVerified)
  useFirebaseConnect([hasUser ? `answers/${user.answerKey}` : 'answers'])
  const userAnswer = useSelector(state => get(state.firebase.data, ['answers', hasUser ? user.value.answerKey : '']))
  const history = useHistory()
  userMemo = user
  userAnswerMemo = userAnswer
  const handleClick = useCallback(async () => {
    setLoading(true)
    try {
      const res = await json(process.env.NODE_ENV === 'production' ? '/requestValidationApp' : 'https://us-central1-tw-cognition.cloudfunctions.net/requestValidationAppLimiter', {
        method: 'POST',
        body: JSON.stringify({
          email: values.email,
          reserve: true,
          ref: qs.get('ref'),
          utm: {
            source: qs.get('utm_source') || '',
            medium: qs.get('utm_medium') || '',
            campaign: qs.get('utm_campaign') || '',
          },
        }),
        headers: {
          'content-type': 'application/json'
        },
      })
      if (res.error) {
        setFieldError('email', res.error)
      } else {
        await setEmail(values.email.toLowerCase())
        if (res.validated) {
          setTimeout(() => {
            history.push(userAnswerMemo && userAnswerMemo.completedAt ? `/result/${userMemo.value.answerKey}` : '/registered')
          })
        } else {
          setIsOpen(true)
        }
      }
    } catch (e) {
      console.error(e)
      setFieldError('email', e.message)
    }

    setLoading(false)
  }, [values.email, setEmail, history, setFieldError, setFieldValue])
  return (
    <Container.Fullpage pt="6" px="6" maxWidth="52em" textAlign="center">
      <Box as={isMobile ? 'div' : Box.AbsCenter}>
        <Box as={isMobile ?  Box.AbsCenter : 'div'} width={responsive('90%', '100%')}>
          <Text fontSize={responsive('1.125rem', '1.5em')} whiteSpace="pre-wrap" fontWeight="bold">
            {'距離下次認知大考驗上線還有一些時間\n若你希望在測驗重新上線後收到通知\n請幫我們留下你的 E-mail'}
          </Text>
          <FormControl
            mt="2"
            mb="5"
            name="email"
            placeholder="email@example.com"
            isReadOnly={user}
            isRequired
            onKeyUp={e => e.keyCode === 13 && setTimeout(() => {
              if (btnRef.current) {
                btnRef.current.click()
              }
            })}
          />
          <Box pt={responsive('8', 0)} />
        </Box>
        <BottomSnapButton
          onClick={handleClick}
          isDisabled={!values.email || errors.email}
          isLoading={loading}
          ref={btnRef}
        >
          確認登記
        </BottomSnapButton>
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
          document.body.style.overflow = ''
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent border="2px solid" rounded="1.5em">
          <ModalHeader>驗證信已寄出</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb="4">
            <Text fontWeight="bold">
              已經寄出一封認證信到你的信箱<br />
              點擊信中連結完成預約認證！
            </Text>
            <Text mt="4">
              若沒有收到認證信，請檢查促銷或垃圾郵件<br />或關閉跳窗再次點擊發送
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>

    </Container.Fullpage>
  );
};

export default BeforeStart;
