import React, { useEffect } from 'react'
import { Divider, Heading } from "@chakra-ui/core";
// import { IoChevronDownCircleOutline } from 'react-icons/io5'
import {
  useDisclosure,
  ModalCloseButton
} from "@chakra-ui/core"
import { Link, Element } from 'react-scroll'
import ReactGA from 'react-ga'

import Box from '../../../components/Box'
import Image from '../../../components/Image'
import Text from '../../../components/Text'
import SectionTitle from '../../../components/SectionTitle'
import ViewableMonitor from '../../../components/ViewableMonitor'
import Circle from '../../../components/Circle';
import theme, { responsive } from '../../../components/ThemeProvider/theme';

import Distance from './Distance';
import CategoryRadar from './CategoryRadar';
// import TypeRadar from './TypeRadar';
import AllEmotion from './AllEmotion';
import CategoryEmotion from './CategoryEmotion';
import AnswerSlider from './AnswerSlider';
import useResponsive from '../../../contexts/mediaQuery/useResponsive';
import useIsLarge from '../../../contexts/largeFont/useIsLarge';

import Conclusion from './Conclusion';
import Practice from '../Practice';
import Action from './Action';
import coffee from './coffee.png';

// const Wrapper = styled(Stack)`
// counter-reset: section;
// h3::before {
//   counter-increment: section;
//   content: counter(section)". ";
// }
// `

// const SITE = 'https://exam.redesigninfo.cc'

const sectionData = [
  {
    title: '九大領域認知偏誤分析',
    name: 'analyze',
    // description: '口口聲聲說你愛臺灣，但你真的了解臺灣嗎？',
    children: [
      {
        title: '整體作答認知落差程度',
        Comp: Distance
      },
      {
        title: '不同領域認知落差程度',
        Comp: CategoryRadar,
      },
    ]
  },
  {
    title: '九大領域情緒影響分析',
    name: 'emotion',
    // description: '每次都自稱中立理性，還是你只是住中壢姓李？',
    children: [
      {
        title: '整體作答情緒傾向',
        Comp: AllEmotion,
      },
      {
        title: '不同領域情緒傾向',
        Comp: CategoryEmotion,
      },
      {
        title: '5件你感到悲觀，但正在變好的事',
        Comp: AnswerSlider,
      },
      {
        title: '5件你感到樂觀，但令人擔憂的事',
        Comp: AnswerSlider,
        positive: false,
      },
    ]
  },
  {
    // title: '測驗總結',
    // description: '每次都自稱中立理性，還是你只是住中壢姓李？',
    children: [
      {
        Comp: Conclusion,
      },
    ]
  },
  {
    // title: '數養練習',
    name: 'practice',
    children: [
      {
        Comp: Practice,
      },
    ]
  },
  {
    title: '後續行動',
    name: 'action',
    children: [
      {
        Comp: Action,
      },
    ]
  }
]

// const ShareButton = props => (
//   <Button
//     as={Link}
//     border="3px solid"
//     borderRadius="1.5em"
//     bg="white"
//     px="1em"
//     py="0.5em"
//     fontSize="1.3755em"
//     height="auto"
//     {...props}
//   />
// )

const Analyze = ({
  result,
  readingData,
  name,
  donate,
  setDonate,
  isActive
}) => {
  const { isMobile } = useResponsive()
  const [isLarge] = useIsLarge()
  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    if (name === 'emotion' && donate) {
      onOpen()
    };
  }, [name, donate])
  return (
    <div>
      <Heading mt="0.75em" as="h1" fontSize={responsive(isLarge ? '2em' : '2.25em', '2.25em')} textAlign="center">你的專屬認知分析報告</Heading>
      {/* <Heading mt="0.5em" as="h3" fontSize={responsive(isLarge ? '1.25em' : '1.5em', '1.5em')} textAlign="center">你的專屬認知分析報告</Heading> */}
      <Box px={isLarge ? 4 : 8}>
        <Divider borderColor="black" borderWidth="0.125em" mt="4" />
        {sectionData.map(({ children, description, title, name }, i) => (
          <Element name={name} key={i}>
            <Box pt="6">
              <Box textAlign="center">
                {title && (
                  <SectionTitle
                    fontWeight="bold"
                    // mb="1rem"
                  >
                    {title}
                  </SectionTitle>
                )}
              </Box>
              <Text fontSize="1.25em">{description}</Text>
              {children.map(({ Comp, title, ...rest }, k) => (
                <Box key={k}>
                  <Box py="6">
                    {title && (
                      <Box textAlign="center" color="white" bg="black" rounded="0.5em">
                        <Text.H4 fontWeight="bold" fontSize="1.125em" lineHeight="1.75">
                          {title}
                        </Text.H4>
                      </Box>
                    )}
                    {/* <TitleWithIcon type={type} title={title} /> */}
                    <ViewableMonitor once>
                      {(visible) => (
                        <Comp showQ visible={visible} {...result} readingData={readingData} isActive={isActive} {...rest} />
                      )}
                    </ViewableMonitor>
                  </Box>
                </Box>
              ))}
            </Box>
          </Element>
        ))}
        <Box
          position="fixed"
          right={responsive('0.5em', '1em')}
          bottom={responsive('1.5em', '2em')}
        >
          {donate && isOpen && (
            <Box.Absolute width="20em" border="1px solid" p="1.5em 1em" top="-0.5em" right="0" transform="translateY(-100%)" bg="white">
              <ModalCloseButton
                right="0.25em"
                _hover={{
                  bg: 'transparent'
                }}
                onClick={() => {
                onClose()
                setDonate(false)
              }} />
              用一杯咖啡，支持我們做更多認知測驗
            </Box.Absolute>
          )}
          <Link
            to="action"
            smooth
            offset={isMobile ? 210 : 100}
            onClick={() => {
              ReactGA.event({
                category: 'Clicks',
                action: '咖啡杯',
              })
            }}
          >
            <Circle
              border="2px solid"
              width="3.5em"
              bg="bgYellow"
              cursor="pointer"
            >
              <Image src={coffee} />
            </Circle>
          </Link>
        </Box>
        {/* <Box pb="12" pt="8"> */}
          {/* <Box px={responsive('8', '25%')}>
            <Image src={more} />
          </Box> */}
          <Text>
            {/* 想提升自己的數據識讀力嗎？繼續往下滑！<br /> */}
            {/* 我們與各領域的專業夥伴，為你準備了專屬的數據識讀學習內容<br />
            限於2021.06.30前有效！ */}
          </Text>
          {/* <Box textAlign="center" mt="1em">
            <Button
              variant="ghost"
              leftIcon={IoChevronDownCircleOutline}
              onClick={() => setTabIndex(1)}
              fontSize={responsive(isLarge ? '1.25em' : '1.375em', '1.375em')}
            >{isMobile ? '往下滑提升你的數據識讀力' : '點此提升你的數據識讀力'}</Button>
          </Box> */}
          {/* <Stack isInline my="1.25em" justifyContent="center" spacing="8">
            <ShareButton href={`https://www.facebook.com/sharer/sharer.php?u=${SITE}/shareResult?score=${result.totalScore}${isLarge ? 'clear=1' : ''}`}>分享結果</ShareButton>
            <ShareButton href={`https://www.facebook.com/sharer/sharer.php?u=${SITE}${isLarge ? '/clear' : ''}?ref=fb`}>分享測驗</ShareButton>
          </Stack> */}
          {/* <Box textAlign="center" my="4">
            <Button width="8em" px="0" onClick={() => setTabIndex(1)} border="3px solid" borderRadius="1.5em" bg="white">前往專屬練習</Button>
          </Box>
          <Box textAlign="center">
            <Button as={Link} href="https://www.facebook.com/sharer/sharer.php?u=" width="8em" px="0" border="3px solid" borderRadius="1.5em" bg="white">分享測驗</Button>
          </Box> */}
        {/* </Box> */}
      </Box>
    </div>
  )
}

export default Analyze
