import { useEffect, useCallback } from 'react'
import useLocalStorage from 'react-use-localstorage';

export default (name, initialVal, devOnly = true, honorInit) => hook => {
  if (typeof window === 'undefined' || (devOnly && process.env.NODE_ENV !== 'development')) {
    return hook(initialVal)
  }
  const [item, setItem] = useLocalStorage(name, JSON.stringify(initialVal));
  const [val, setVal] = hook(item && JSON.parse(item))
  const superSetVal = useCallback((v) => {
    setVal(v)
    setItem(JSON.stringify(v))
  }, [setItem, setVal])

  useEffect(() => {
    if (honorInit && initialVal) {
      superSetVal(initialVal)
    }
  }, [])

  return [val, superSetVal]
}
