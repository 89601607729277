import React from 'react'
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerOverlay,
  DrawerContent,
  Stack,
  IconButton,
} from "@chakra-ui/core";
import { MdMenu } from 'react-icons/md';

import Box from '../components/Box';
import Flex from '../components/Flex';
import Image from '../components/Image';
import Text from '../components/Text';
import Link from '../components/Link';
import { responsive } from '../components/ThemeProvider/theme';

import taiwan from './taiwan.png'
import useUserAnswer from '../contexts/user/useUserAnswer'
import useIsLarge from '../contexts/largeFont/useIsLarge'

const menuButton = [
  ['/design', '測驗設計'],
  ['/about', '團隊簡介'],
  ['/contact', '聯絡我們'],
  ['/support', '支持我們'],
  ['/', '回到首頁'],
]

const Header = ({ siteTitle, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const answer = useUserAnswer()
  const [isLarge] = useIsLarge()
  return (
    <>
      <Flex
        position="fixed"
        bg="black"
        top={0}
        left={0}
        right={0}
        justifyContent="center"
        zIndex="docked"
        py="0.75em"
        px="3.5em"
        {...props}
      >
        <Flex as={Link} to={isLarge ? '/clear' : '/'} justifyContent="center" alignItems="center">
          <Box pr={responsive('0.25em', '0.5em')} width={responsive('1em', '1.5em')}>
            <Image src={taiwan} />
          </Box>
          <Box color="white">
            <Text fontWeight="bold" fontSize={responsive('1em', '1.5em')}>臺灣認知大考驗</Text>
          </Box>
        </Flex>
        <Box.Absolute left="1em" top="50%" transform="translateY(-50%)">
          <IconButton
            width="2em"
            flex={1}
            onClick={onOpen}
            variantColor="white"
            border="none"
            aria-label="開啟選單"
            borderRadius="0"
            icon={() => <MdMenu size="2em" />}
          />
        </Box.Absolute>
      </Flex>
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody py="20">
            <Stack spacing="6">
              {[answer && answer.completedAt ? [`/result/${answer.key}`, '我的報告'] : [], ...menuButton].map(([to, label], k) => to && (
                <Link
                  key={k}
                  to={to}
                  width="100%"
                  variant="ghost"
                  variantColor="black"
                  onClick={() => {
                    onClose()
                  }}
                >{label}</Link>
              ))}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
export default Header
