import React from 'react'

import Box from '../Box'
import BackgroundImage from '../BackgroundImage'

import face1 from './face-1.svg'
import face2 from './face-2.svg'
import face3 from './face-3.svg'

export const faces = [face3, face2, face1]

const Face = ({ active, n, ...props }) => {
  return (
    <Box {...props}>
      <Box
        borderRadius="50%"
        backgroundColor={active ? 'primary' : 'white'}
        border="0.25em solid black"
      >
        <BackgroundImage src={faces[n]} />
      </Box>
    </Box>
  )
}

export default Face
