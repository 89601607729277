const images = {
  101: require('./101.jpg').default,
  102: require('./102.jpg').default,
  104: require('./104.jpg').default,
  105: require('./105.jpg').default,
  114: require('./114.jpg').default,
  117: require('./117.jpg').default,
  12: require('./12.jpg').default,
  120: require('./120.jpg').default,
  123: require('./123.jpg').default,
  127: require('./127.jpg').default,
  130: require('./130.jpg').default,
  131: require('./131.jpg').default,
  132: require('./132.jpg').default,
  135: require('./135.jpg').default,
  136: require('./136.jpg').default,
  138: require('./138.jpg').default,
  139: require('./139.jpg').default,
  144: require('./144.jpg').default,
  145: require('./145.jpg').default,
  147: require('./147.jpg').default,
  153: require('./153.jpg').default,
  156: require('./156.jpg').default,
  159: require('./159.jpg').default,
  160: require('./160.jpg').default,
  162: require('./162.jpg').default,
  163: require('./163.jpg').default,
  165: require('./165.jpg').default,
  178: require('./178.jpg').default,
  181: require('./181.jpg').default,
  205: require('./205.jpg').default,
  208: require('./208.jpg').default,
  214: require('./214.jpg').default,
  215: require('./215.jpg').default,
  217: require('./217.jpg').default,
  218: require('./218.jpg').default,
  220: require('./220.jpg').default,
  23: require('./23.jpg').default,
  244: require('./244.jpg').default,
  246: require('./246.jpg').default,
  247: require('./247.jpg').default,
  249: require('./249.jpg').default,
  25: require('./25.jpg').default,
  250: require('./250.jpg').default,
  252: require('./252.jpg').default,
  253: require('./253.jpg').default,
  254: require('./254.jpg').default,
  255: require('./255.jpg').default,
  256: require('./256.jpg').default,
  257: require('./257.jpg').default,
  258: require('./258.jpg').default,
  259: require('./259.jpg').default,
  260: require('./260.jpg').default,
  261: require('./261.jpg').default,
  262: require('./262.jpg').default,
  268: require('./268.jpg').default,
  269: require('./269.jpg').default,
  27: require('./27.jpg').default,
  270: require('./270.jpg').default,
  271: require('./271.jpg').default,
  272: require('./272.jpg').default,
  273: require('./273.jpg').default,
  276: require('./276.jpg').default,
  278: require('./278.jpg').default,
  281: require('./281.jpg').default,
  29: require('./29.jpg').default,
  300: require('./300.jpg').default,
  303: require('./303.jpg').default,
  32: require('./32.jpg').default,
  33: require('./33.jpg').default,
  69: require('./69.jpg').default,
  75: require('./75.jpg').default,
  78: require('./78.jpg').default,
  91: require('./91.jpg').default,
  93: require('./93.jpg').default,
  97: require('./97.jpg').default,
  98: require('./98.jpg').default,
  99: require('./99.jpg').default,
}

export default images
