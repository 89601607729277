import { css } from '@emotion/core'

export default css`
  html {
    color: black;
  }

  body {
    min-width: 100%;
    min-height: 100%;
    touch-action: manipulation;
  }

  img {
    width: 100%;
  }
`;
