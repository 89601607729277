import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router'
import { json } from 'd3-fetch'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import Container from '../../components/Container'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Image from '../../components/Image'
import Text from '../../components/Text'
import BottomSnapButton from '../../components/BottomSnapButton'
import { responsive } from '../../components/ThemeProvider/theme';
import FormControl from '../../components/FormControl'
import useResponsive from '../../contexts/mediaQuery/useResponsive'

import introDoor from '../intro-door-man.svg'
// import clock from './clock.png'
// import chat from './chat.png'
// import analyst from './analyst.png'
// import linechart from './linechart.png'
// import logos from '../ResultPage/Practice/logos/logos.png'
import file from '../file.png'
import chart from '../chart.png'
import map from '../map.png'
import cofact from './cofact.png'
import elite from './elite.png'
import exercise from './exercise.png'
import giloo from './giloo.png'
import ps from './ps.png'
import useUser from '../../contexts/user/useUser'
import useQs from '../../contexts/qs/useQs'

export const logo = [
  { src: giloo, width: responsive('4em' ,'4.5em') },
  { src: elite, width: responsive('6.25em' ,'7.25em') },
  { src: ps, width: responsive('3.25em' ,'3.75em') },
  { src: exercise, width: responsive('8.75em' ,'10em') },
  { src: cofact, width: responsive('6.5em' ,'7.5em') },
]

const content = [
    {
      src: file,
      title: '作答過程即時存檔',
      text: '預防測驗中離開失去填答紀錄'
    },
    {
      src: chart,
      title: '完整結果分析報告',
      text: '隨時可查填答紀錄及專屬獎勵'
    },
    {
      src: map,
      title: '更多台灣事實測驗',
      text: '優先收到重要事實相關測驗的線上通知'
    },
  ]

export const Board = ({ title, children, childPx, ...props }) => (
  <Box
    mb={responsive('6', '8')}
    borderRadius="0.5em"
    fontSize={responsive('1em', '1.25em')}
    border="1px solid"
    {...props}
  >
    <Box
      bg="black"
      textAlign="center"
      letterSpacing="0.125em"
      color="white"
      borderRadius="0.5em 0.5em 0 0"
      py="0.5em"
    >{title}</Box>
    <Box px={childPx || "1.5em"} py={responsive('0.625em', '1rem')} borderRadius="2em 2em 0 0">
      {children}
    </Box>
  </Box>
)

const Introduction = () => {
  const [isLoading, setIsLoading] = useState()
  const { setEmail, answerKey } = useUser()
  const { isMobile } = useResponsive()
  const [{ value }, { error }, { setError }] = useField({ name: 'email' })
  const history = useHistory()
  const qs = useQs()
  const userAnswer = useSelector(state => get(state.firebase.data, ['answers', answerKey]))

  const handler = async () => {
    if (value) {
      setIsLoading(true)
      try {
        const res = await json(process.env.NODE_ENV === 'production' ? '/requestValidationApp' : 'https://us-central1-tw-cognition.cloudfunctions.net/requestValidationAppLimiter', {
          method: 'POST',
          body: JSON.stringify({
            email: value,
            ref: qs.get('ref'),
            utm: {
              source: qs.get('utm_source') || '',
              medium: qs.get('utm_medium') || '',
              campaign: qs.get('utm_campaign') || '',
            },
          }),
          headers: {
            'content-type': 'application/json'
          },
        })
        if (res.error) {
          setError(res.error)
        } else {
          await setEmail(value.toLowerCase())
          setTimeout(() => {
            history.push(userAnswer && userAnswer.completedAt && answerKey ? `/result/${answerKey}` : '/stage')
          })
        }
      } catch (e) {
        console.error(e)
        setError(e.message)
      }
    } else {
      history.push('/stage')
    }

    setIsLoading(false)
  }
  return (
    <Container pt="5.75em" position="relative" px={responsive(8, '10%')}>
      <Board title="測驗前填寫e-mail，你可以獲得" childPx={responsive('1em', '3.5em')}>
        {content.map(({ src, text, title }, i) => (
          <Flex key={i} mt={i && '0.5em'} align="center">
            <Box width="2.5em">
              <Image src={src} />
            </Box>
            <Box ml="0.5em" flex={1}>
              <Text.Bold>{title}</Text.Bold>
              <Text fontSize="0.875em">{text}</Text>
            </Box>
          </Flex>
        ))}
        <FormControl
          mt="2"
          mb="5"
          name="email"
          isRequired={false}
          placeholder="輸入e-mail並點擊黃色按鈕開始測驗"
          displayError
        />
      </Board>
      <Text textAlign="center" pb={responsive('0.5em', '1em')}>由以下專業內容夥伴共同推薦</Text>
      <Flex alignItems="center" flexWrap="wrap" justifyContent="center" px={responsive('0', '20%')}>
        {logo.map((d, k) => (
          <Box width={d.width} mx="0.5em" key={k}>
            <Image src={d.src} />
          </Box>
        ))}
      </Flex>
      {!isMobile && (
        <Box bg="white" width="7.25rem" position="absolute" bottom="3em" right="-15%">
          <Image src={introDoor} />
        </Box>
      )}
      <BottomSnapButton
        onClick={(e) => {
          e.preventDefault()
          handler()
        }}
        square
        isLoading={isLoading}
        isDisabled={error}
      >開始測驗</BottomSnapButton>
    </Container>
  )
}

export default Introduction
