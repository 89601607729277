import React from 'react'

import Box from '../Box'
import BackgroundImage from '../BackgroundImage'
import Heading from '../Heading'
import { responsive } from '../ThemeProvider/theme'

import bg from './section-title.svg'
import useIsLarge from '../../contexts/largeFont/useIsLarge'

const SectionTitle = ({ children, ...props }) => {
  const [isLarge] = useIsLarge()

  return (
    <BackgroundImage ratio={130 / 540} src={bg} {...props}>
      <Box.AbsCenter width="100%" textAlign="center">
        <Heading fontSize={responsive(isLarge ? '1.25em' : '1.375em', '1.75em')}>{children}</Heading>
      </Box.AbsCenter>
    </BackgroundImage>
  )
}

export default SectionTitle
