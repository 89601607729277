import React from 'react'

import Container from '../../components/Container'
import Text from '../../components/Text'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
// import Door from '../../components/Door'
import Image from '../../components/Image'
import Button from '../../components/Button'

import data from './data'
import getAnswerStats from '../QuestionPage/getAnswerStats'
import useResponsive from '../../contexts/mediaQuery/useResponsive';

import door from './door.png'
import doorhealth from './doorhealth.png'
import doorliving from './doorliving.png'
import doorworks from './doorworks.png'
import doornature from './doornature.png'
import doorsafety from './doorsafety.png'
import doorother from './doorother.png'
import doorsocial from './doorsocial.png'
import doorindustry from './doorindustry.png'
import dooreducation from './dooreducation.png'
import dooreconomy from './dooreconomy.png'

import { responsive } from '../../components/ThemeProvider/theme'
import styled from '@emotion/styled'
import useIsLarge from '../../contexts/largeFont/useIsLarge'

// const texts = [
//   '從你最有興趣的領域開始吧！',
//   '打開所有的門取得專屬於你的認知地圖'
//   // '選一個你認為很重要的領域吧',
//   // '再來試試你很有把握的領域吧！',
//   // '最近引起你注意的議題是哪個領域呢',
//   // '選一個你常在新聞上看到的領域吧！',
//   // '和你的生活最相關的是哪個領域呢',
//   // '試試看不那麼熟悉但同樣重要的領域',
//   // '接下來選擇一個比較熟悉的領域吧！',
//   // '挑戰看看你認為可能比較難的領域吧',
//   // '完成最後一關，解鎖你與台灣的距離',
// ]

const StyledButton = styled(Button.Invisible)`
&:hover {
  .label {
    color: black;
  }
}
`

const pitcures = {
  health: doorhealth,
  safety: doorsafety,
  nature: doornature,
  living: doorliving,
  social: doorsocial,
  economy: dooreconomy,
  education: dooreducation,
  other: doorother,
  works: doorworks,
  industry: doorindustry,
}

const getDoorBox = ({ name, key }) => {
  const { stageClear } = getAnswerStats()
  return (
    <Box.Relative key={key} width="25%" px="2">
      <StyledButton
        display="block"
        width="100%"
        height="auto"
        color="black"
        bg="transparent"
        border="none"
        _hover={{ bg: "transparent", color: 'primary' }}
        _active={{ bg: "transparent" }}
        to={`/stage/${key}`}
        isDisabled={stageClear[key]}
        pt="2em"
      >
        <Box.Absolute
          bg="currentColor"
          p="1"
          border="2px solid black"
          borderRadius="1.5em"
          top="0"
          left="50%"
          transform="translateX(-50%)"
        >
          <Text
            fontWeight="normal"
            letterSpacing="wide"
            fontSize={responsive('0.75em', '1em')}
            color="white"
            width="5em"
            className="label"
            transition="all 0.15s ease-out"
          >{name}</Text>
        </Box.Absolute>
        <Box mb="4" mt={responsive('0', '2')}>
          {stageClear[key] ? <Image src={pitcures[key]} /> : (
            <Image src={door} />
          )}
        </Box>
      </StyledButton>
    </Box.Relative>
  )
}

const FlexRow = props => (
  <Flex justifyContent="center" my="2" {...props} />
)

const StagePage = () => {
  const { clearCount } = getAnswerStats()
  const { isMobile } = useResponsive()
  const [isLarge] = useIsLarge()
  return (
    <Box py="4">
      <Container textAlign="center" px={isLarge ? 2 : 6} pt="1em">
        <Box mb="10" mt="2.75rem">
          <Text fontWeight="bold" fontSize={responsive(isLarge ? '1.125em' : '1.25em', '2.5em')} whiteSpace="pre-wrap">{clearCount ? '選擇下一個領域，繼續前進吧！' : '從你最有興趣的領域開始作答吧！'}</Text>
          <Text fontSize={responsive('1em', '1.25em')}>每題請在<Text.Inline fontWeight="bold">20秒</Text.Inline>內以直覺作答</Text>
        </Box>
        {clearCount === 10 && (
          <Box mb="8">
            <Button to="/info">領取我的報告</Button>
          </Box>
        )}
        {isMobile ?
          <>
            <FlexRow>
              {data.slice(0, 3).map(getDoorBox)}
            </FlexRow>
            <FlexRow>
              {data.slice(3, 7).map(getDoorBox)}
            </FlexRow>
            <FlexRow>
              {data.slice(7).map(getDoorBox)}
            </FlexRow>
          </>
          :
          <Box px="5%">
            <FlexRow>
              {data.slice(0, 5).map(getDoorBox)}
            </FlexRow>
            <FlexRow>
              {data.slice(5).map(getDoorBox)}
            </FlexRow>
          </Box>
        }
      </Container>
    </Box>
  )
}

export default StagePage
