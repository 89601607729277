import React from 'react'
import { Textarea } from "@chakra-ui/core";

const ReTextarea = (props) => {
  return (
    <Textarea
      borderColor="black"
      focusBorderColor="yellow.600"
      {...props}
    />
  )
}

export default ReTextarea
