import React, { useRef, useState, useCallback } from 'react';
import { useFormikContext } from 'formik'
import { useHistory } from 'react-router-dom'
import nativeFirebase from 'firebase'

import Container from '../components/Container'
import Box from '../components/Box'
import Text from '../components/Text'
import FormControl from '../components/FormControl'
import BottomSnapButton from '../components/BottomSnapButton'
import { responsive } from '../components/ThemeProvider/theme';

// import introDoor from './intro-door-man.svg'
import useResponsive from '../contexts/mediaQuery/useResponsive';

const BeforeStart = () => {
  const { setFieldError, errors, values } = useFormikContext()
  const btnRef = useRef()
  const [loading, setLoading] = useState()
  const { isMobile } = useResponsive()
  const history = useHistory()
  const handleClick = useCallback(async () => {
    setLoading(true)
    try {
      const res = await nativeFirebase.functions().httpsCallable('getResult')({ email: values.email.toLowerCase() })
      console.log(res)
      if (res.data.key) {
        setTimeout(() => {
          history.push(`/result/${res.data.key}`)
        })
      }
    } catch (e) {
      console.error(e)
      setFieldError('email', e.message)
    }
    setLoading(false)
  }, [values.email, history, setFieldError])
  return (
    <Container.Fullpage pt="6" px="6" maxWidth="50em" textAlign="center">
      <Box as={isMobile ? 'div' : Box.AbsCenter}>
        <Box as={isMobile ?  Box.AbsCenter : 'div'} width={responsive('90%', '100%')}>
          <Text fontSize={responsive('1.125em', '1.5em')} whiteSpace="pre-wrap" fontWeight="bold">
            請輸入當初參加考驗時登記的 E-mail<br />
            讓我們為您查詢測驗結果
          </Text>
          <FormControl
            mt="2"
            mb="5"
            name="email"
            placeholder="email@example.com"
            isRequired
            onKeyUp={e => e.keyCode === 13 && setTimeout(() => {
              if (btnRef.current) {
                btnRef.current.click()
              }
            })}
          />
          <Box pt={responsive('8', 0)} />
        </Box>
        <BottomSnapButton
          onClick={handleClick}
          isDisabled={!values.email || errors.email}
          isLoading={loading}
          ref={btnRef}
        >
          查詢我的測驗結果
        </BottomSnapButton>
      </Box>
    </Container.Fullpage>
  );
};

export default BeforeStart;
