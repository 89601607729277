import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFirebaseConnect, isLoaded } from 'react-redux-firebase'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import sumBy from 'lodash/sumBy'
import groupBy from 'lodash/groupBy'

import questions, { questionsByCategory } from '../QuestionPage/data'

const arrSum = (a, b) => a.map((v, i) => v + b[i])

const orderBy = (key, desc) => data => {
  const grouped = groupBy(data, key)
  return Object.entries(grouped).sort(([a], [b]) => (a - b) * (desc || -1)).map(d => d[1].map(f => f.name))
}

const useResult = key => {
  useFirebaseConnect(`answers/${key}`)
  const result = useSelector(state => get(state.firebase.data, ['answers',key]))
  return useMemo(() => {
    if (isLoaded(result)) {
      const biasScores = result.q.map((ans, i) => {
        const q = questions[i]
        return q.answer.length === 1 && ans > -1 ? (ans - q.answer[0]) * (q.emotion || 1) : 0
      })
      const categoryScores = mapValues(questionsByCategory, (qs, name) => {
        const score = qs.reduce((total, q) => {
          const ans = result.q[q.no - 1]
          total.score += q.answer.some(a => ans == a)
          total.duration += result.qDuration[q.no - 1]
          if (ans > -1) {
            // 紀錄答題的樂/悲觀偏向
            const bias = (ans - 1) * (q.emotion || 1)
            const s = Math.sign(bias)
            total.bias[s] += bias * s
            total.emotion[q.emotion === '-1' ? ans : 2 - ans] += 1
          }
          return total
        }, {
          name,
          score: 0,
          duration: 0,
          bias: { '1': 0, '-1': 0, '0': 0 },
          emotion: Array(3).fill(0),
        })
        return score
      })
      const orderByDuration = orderBy('duration', true)(categoryScores)
      const orderByScores = orderBy('score', true)(categoryScores)
      const orderByBiasPos = orderBy('bias.1')(categoryScores)
      const orderByBiasNeg = orderBy('bias.-1')(categoryScores)
      const types = questions.reduce((all, q) => {
        all[q.type] = all[q.type] || { count: 0, score: 0}
        all[q.type].count += 1
        all[q.type].score += result.q[q.no - 1] == q.answer
        return all
      }, {})
      return {
        loaded: true,
        result,
        categoryScores,
        biasScores,
        orders: {
          score: orderByScores,
          positive: orderByBiasPos,
          negative: orderByBiasNeg,
          duration: orderByDuration,
        },
        typeScores: mapValues(types, t => t.score / t.count),
        totalScore: sumBy(Object.values(categoryScores), 'score'),
        totalEmotion: Object.values(categoryScores).reduce((all, { emotion }) => arrSum(all, emotion), Array(3).fill(0)),
        categoryOrder: result.stageStart ? Object.entries(result.stageStart).sort(([, ta], [, tb]) => ta - tb).map(d => d[0]) : [],
      }
    }
    return { loaded: false }
  }, [result])
}

export default useResult
