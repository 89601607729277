import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { CircularProgress, CircularProgressLabel, Stack } from "@chakra-ui/core";
import { useField, useFormikContext } from 'formik'
import get from 'lodash/get'
import ReactMarkdown  from 'react-markdown'

// import Container from '../../components/Container'
import Text from '../../../components/Text'
import Heading from '../../../components/Heading'
import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
// import FullpageLoading from '../../../components/FullpageLoading'
import theme, { responsive } from '../../../components/ThemeProvider/theme';

import useQs from '../../../contexts/qs/useQs'

import Option from './Option'
import questions from './data'
// import useQuestions from './useQuestions';

let timer
let navigation
// let startT
const seconds = 20

const QuestionPage = ({ match: { params: { no } } }) => {
  // const { questions, loading } = useQuestions()
  const question = get(questions, no - 1)
  const qIndex = get(question, 'no') - 1
  const name = `q.${qIndex}`
  const history = useHistory()
  const [time, setTime] = useState(seconds)
  const [, , meta] = useField({ name })
  // const [, , durMeta] = useField({ name: `qDuration.${qIndex}` })
  const { handleSubmit } = useFormikContext()
  const [confirmed, setConfirm] = useState(false)
  const params = useQs()
  const showAns = params.get('showAns')
  // const { isMobile } = useResponsive()

  const delayGoNext = () => {
    // durMeta.setValue(new Date() - startT)
    navigation = setTimeout(() => {
      if (no == questions.length) {
        handleSubmit()
      } else {
        history.push(`/tpe/q/${+no + 1}`)
      }
    }, 1000)
  }

  const countdown = (prevTime) => {
    timer = setTimeout(() => {
      if (document.visibilityState === 'visible') {
        setTime(prevTime - 1)
        if (prevTime - 1) {
          countdown(prevTime - 1)
        } else {
          meta.setValue('-1')
          delayGoNext()
        }
      } else {
        countdown(prevTime)
      }
    }, 1000)
  }

  const onAnswer = () => {
    clearTimeout(timer)
    timer = null
    delayGoNext()
  }

  useEffect(() => {
    if (confirmed) {
      onAnswer()
    }
  }, [confirmed])

  useEffect(() => {
    setTime(seconds)
    setConfirm(false)
    countdown(seconds)
    // startT = new Date()
    return function cleanup() {
      if (timer) clearTimeout(timer)
      if (navigation) clearTimeout(navigation)
    }
  }, [qIndex])
  // if (loading) return <FullpageLoading />
  return (
    <Box pt={theme.headerHeight}>
      <Flex
        alignItems="center"
        justifyContent="space-around"
        borderBottom="2.5px solid"
        mx={responsive('0', '10em')}
        px="10"
        py={responsive('2', '6')}
        mt={responsive(0, '4em')}
      >
        <Box width="100%" textAlign="right">
          <CircularProgress value={time / seconds * 100} color="clock">
            <CircularProgressLabel>
              <Text fontSize="1.125em">{time}</Text>
            </CircularProgressLabel>
          </CircularProgress>
        </Box>
      </Flex>
      <Box
        as={Flex}
        pt={responsive('4', '2em')}
        px={responsive('2', 0)}
        mx={responsive('0', '10em')}
        flexDirection={responsive('column', null, 'row')}
        justifyContent="center"
        alignItems={responsive('flex-start', 'center')}
      >
        <Box p="6">
          <Flex justifyContent="space-between" alignItems="center" mb="4">
            <Heading as="h4" fontSize={responsive('1.25em', '2em')} >
              第{no}題
            </Heading>
          </Flex>
          <Box fontSize={responsive('1.5em', '2.5em')}>
            <ReactMarkdown source={question.question} />
          </Box>
        </Box>
        <Stack width={responsive('100%', '100%', 2 / 5)} my="4" px={responsive('12', '4')} spacing="6">
          {question.options.map((label, i) => (
            <Option
              key={`${qIndex}${i}`}
              name={name}
              label={label}
              index={i}
              confirmed={confirmed}
              showAns={showAns == 1}
              onConfirm={() => setConfirm(true)}
              isAnswer={question.answer == i}
              // onCancel={() => meta.setValue(null)}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  )
}

export default QuestionPage
