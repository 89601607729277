import React from 'react'

import FullpageHeight from '../../components/FullpageHeight'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Heading from '../../components/Heading'
import Text from '../../components/Text'
import Image from '../../components/Image'
import BottomSnapButton from '../../components/BottomSnapButton'
import { responsive } from '../../components/ThemeProvider/theme';
import LabelText from '../../components/LabelText'

import useResponsive from '../../contexts/mediaQuery/useResponsive';

import cover from '../cover.png'

const HomePage = () => {
  const { isMobile } = useResponsive()
  const content = (
    <Box px={responsive('4', '6')} py={responsive('1.25em', '12')} textAlign={responsive('left', 'right')}>
      <Heading fontWeight={700} fontSize={responsive('1em', '1.75em')} lineHeight="tall">
        用<Text.PingFang as="span" fontSize="1em">3</Text.PingFang>分鐘的時間，一起看見你不知道的臺北
      </Heading>
    </Box>
  )

  const action = <BottomSnapButton textAlign={responsive('', 'right')} to="/tpe/start">點擊進入測驗</BottomSnapButton>
  return (
    <Box mx="auto" maxWidth="76em">
      <FullpageHeight pt="4em" position="relative">
        <Box as={!isMobile && Box.AbsCenter} width="100%">
          <Flex justifyContent="center" flexDirection={responsive('column-reverse', 'row')} alignItems="center">
            <Box px="1" mt={responsive('-3em', 0)}>
              <Box px={responsive('10%', 0)}>
                <Image src={cover} />
              </Box>
              {isMobile && content}
            </Box>
            <Box width={responsive('100%', 1 / 2)} px="2" >
              <Box
                textAlign="right"
                borderBottom={responsive('none', '2px solid')}
                pb={responsive(0, '2.25em')}
              >
                <LabelText>測測你與臺北的距離</LabelText>
                <Text mt="0.75rem" lineHeight="1.2" fontWeight="900" fontSize={responsive('2.75em', '5em')}>臺北認知{isMobile && <br />}大考驗</Text>
              </Box>
              {!isMobile && (
                <>
                {content}
                {action}
                </>
              )}
            </Box>
          </Flex>
        </Box>
        {isMobile && action}
      </FullpageHeight>
    </Box>
  )
}

export default HomePage
