import React, { useEffect, useMemo } from 'react';
import { Form, useFormikContext } from 'formik'
import ReactGA from 'react-ga';

import { useFirebase } from 'react-redux-firebase'
import { withRouter } from 'react-router';
import nativeFirease from 'firebase'

import useUser from './contexts/user/useUser'
import useUserAnswer from './contexts/user/useUserAnswer'
import sanitizer from './sanitizer';

export const STORAGE_KEY = 'TW_COGNITION_V4'

const resultClean = /(\/result)\/[^/]+/

let loading
let prevUser
let answerLoaded
const FormStorage = ({ children, history, location }) => {
  const { values, setValues } = useFormikContext()
  const { user, hasUser, answerKey } = useUser()
  const firebase = useFirebase()
  const userAnswer = useUserAnswer()
  useEffect(() => {
    if (hasUser) {
      if (prevUser !== user.key) {
        setValues({ email: user.value.email })
        answerLoaded = false
      }
      prevUser = user.key
    }
  }, [user, hasUser])
  useEffect(() => {
    if (!answerLoaded && userAnswer) {
      setTimeout(() => {
        setValues({ ...values, ...userAnswer })
      })
      answerLoaded = true
      ReactGA.set({ userId: userAnswer.key });
    }
  }, [userAnswer])
  useEffect(() => {
    ReactGA.pageview(location.pathname.replace(resultClean, '$1'));
    if (history.action !== 'POP') {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(values))
      if (hasUser && !loading) {
        if (answerKey) {
          const sanitized = sanitizer(values)
          loading = true
          firebase.update(`answers/${answerKey}`, ({
            ...sanitized,
            updatedAt: nativeFirease.database.ServerValue.TIMESTAMP,
            uid: user.key,
          })).then(() => {
            loading = false
          })
        }
      }
    }
  }, [location])
  return useMemo(() => (
    <Form>
      {children}
    </Form>
  ), [children]);
};

export default withRouter(FormStorage);
