import { useFirebaseConnect } from 'react-redux-firebase'
import get from 'lodash/get'
import { useSelector } from 'react-redux'

const useUserCount = () => {
  useFirebaseConnect(['userCount'])
  const userCount = useSelector(state => get(state.firebase.data, 'userCount', 0))

  return userCount
}

export default useUserCount
