import React, { useState } from 'react'
import Context from './context'

const Provider = ({ children }) => {
  const [money, donate] = useState(70)
  return (
  <Context.Provider value={{ money, donate }}>
    {children}
  </Context.Provider>
)}

export default Provider;
