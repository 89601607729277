import React from 'react';
import { Stack, useDisclosure } from '@chakra-ui/core'
import { useFormikContext } from 'formik'

import Box from '../components/Box'
import Container from '../components/Container'
import Heading from '../components/Heading'
import Text from '../components/Text'
import Button from '../components/Button'
import FormControl from '../components/FormControl'
import ModalModule from '../components/ModalModule'
import BottomSnapButton from '../components/BottomSnapButton'
import { responsive } from '../components/ThemeProvider/theme';

const fields = [
  {
    name: 'gender',
    label: '性別',
    placeholder: '請選擇',
    type: 'select',
    options: ['男', '女', '其他'],
    isRequired: true,
  },
  {
    name: 'age',
    label: '年齡',
    placeholder: '請填入',
    isRequired: true,
  },
  {
    name: 'city',
    label: '現居城市',
    type: 'select',
    placeholder: '請選擇',
    options: [
      '台北市', '基隆市', '新北市', '宜蘭縣', '桃園市', '新竹市', '新竹縣', '苗栗縣',
      '台中市', '彰化縣', '南投縣', '嘉義市', '嘉義縣', '雲林縣', '台南市', '高雄市',
      '澎湖縣', '金門縣', '屏東縣', '台東縣', '花蓮縣', '連江縣', '其他'
    ],
    isRequired: true,
  },
]

const Submit = () => {
  const { isSubmitting } = useFormikContext()
  return (
    <BottomSnapButton type="submit" isLoading={isSubmitting} loadingText="送出中">
      完成送出
    </BottomSnapButton>
  )
}

const InfoPage = () => {
  // const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Container.Fullpage withHeader>
      <Box p="3" maxWidth="40em" mx="auto">
        <Box px="3" pt={responsive(0, '4em')} pb="3" mx="-0.75em">
          <Heading>恭喜你完成作答</Heading>
          <Text mt="2">
          需要你的協助，讓報告更完整！達一萬人填答後，製作團隊將彙整所有填答結果，以去識別化的資料<sup>1</sup>交叉分析，產出萬人分析報告！
          </Text>
          {/* <Button.Invisible color="black" onClick={onOpen}>萬人分析報告會如何使用我的個人資料？</Button.Invisible> */}
        </Box>
        <Stack spacing="4" my="4">
          {fields.map(field => (
            <FormControl {...field} key={field.name} />
          ))}
        </Stack>
        <Text mt="2" fontSize="0.875em">
          <sup>1</sup>萬人分析報告不會揭露你的電子郵件地址
        </Text>
      </Box>
      <Submit />
      {/* <ModalModule
        open={isOpen}
        close={onClose}
      >
        <Text>
          製作團隊會將資料去識別化後，以地區、性別、年齡層分類，交叉分析所有填答者的填答狀況。所有個人資料將依個人資料保護法蒐集及使用。
        </Text>
        <Box mt="4" textAlign="center">
          <Button onClick={onClose}>我瞭解了</Button>
        </Box>
      </ModalModule> */}
    </Container.Fullpage>
  );
};

export default InfoPage;
