import React, { useContext, useMemo, useRef, useState } from 'react'
import {
  FormControl,
  FormLabel,
  Heading,
  Radio,
  Stack,
} from "@chakra-ui/core";
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup'
import firebase from 'firebase'
import { Redirect } from 'react-router';
import ReactGA from 'react-ga'

import Box from '../../../components/Box'
import Image from '../../../components/Image'
import Button from '../../../components/Button'
import Container from '../../../components/Container'
import Input from '../../../components/Input'
import Text from '../../../components/Text'
import Flex from '../../../components/Flex'
import Select from '../../../components/Select'
import donateContext from '../../../contexts/donate/context';

import { program } from './'

export const fields = [
  { name: 'name', label: '姓名' },
  { name: 'email', label: 'E-mail' },
  { name: 'phone', label: '電話' },
  { name: 'address', label: '收件地址' },
  {
    name: 'checkout_method',
    label: '付款方式',
    type: 'radio',
    options: [
      { value: 'credit', label: '信用卡' },
      { value: 'atm', label: 'ATM轉帳' },
    ],
  },
]

const getField = f => {
  if (f.type === 'radio') {
    return (
      <Flex mx="-1em">
        {f.options.map(opt => (
          <Field name={f.name} type="radio" value={opt.value} key={opt.value}>
            {({ field }) => <Radio variantColor="yellow" mx="1em" {...field}>{opt.label}</Radio>}
          </Field>
        ))}
      </Flex>
    )
  }
  if (f.type === 'select') {
    return <Field name={f.name}>
      {({ field }) => <Select {...field} options={f.options} />}
    </Field>
  }
  return <Input.Field borderRadius="1.5em" name={f.name} />
}

const renderField = (f) => (
  <FormControl key={f.name} isRequired={f.noRequired ? false : true}>
    <FormLabel>{f.label}</FormLabel>
    {getField(f)}
  </FormControl>
)

const programs = [
  ...program,
  {
    noRequired: ['address'],
    adj: '自由贊助',
    id: 18309,
  }
]

const Checkout = ({ match: { params: { id } } }) => {
  const [checkoutError, setCheckoutError] = useState()
  const formRef = useRef()
  const data = useMemo(() => programs.find(p => p.id == id), [id])
  const { money } = useContext(donateContext)
  const schema = useMemo(() => yup.object(Object.assign({
    name: yup.string().required('此欄位必填'),
    email: yup.string().email().required('此欄位必填'),
    phone: yup.string().required('此欄位必填'),
    checkout_method: yup.string().required('此欄位必填'),
    address: yup.string().required('此欄位必填'),
  }, data?.options ? {
    size: yup.string().required('此欄位必填'),
    variation: yup.string().required('此欄位必填'),
  } : {}, Boolean(data.noRequired) && data.noRequired.reduce((res, d) => {
    res[d] = yup.string()
    return res
  }, {}))), [data])
  if (!data) return <Redirect to="/support" />
  return (
    <Container px="8" py="4" maxWidth="50em" mx="auto">
      <Box mt="3.75em" mb="0.5em">
        <Image src={data.icons} />
      </Box>
      <Heading fontSize="1.75em">感謝支持！<br/>請填寫你的付款資料</Heading>
      <Formik
        initialValues={{ name: '', email: '', phone: '', productId: id, amount: money }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const createOrderAndPay = firebase.functions().httpsCallable('createOrderAndPay')
            // const res = await createOrderAndPay(values)
            const response = await createOrderAndPay(values)
            if (response.data.redirectTo) {
              window.location.href = response.data.redirectTo
            } else {
              formRef.current.innerHTML = response.data.html
              formRef.current.action = response.data.action
              formRef.current.submit()
            }
          } catch (e) {
            setCheckoutError(true)
            setSubmitting(false)
          }
        }}
        validationSchema={schema}
      >
        {({ isSubmitting }) => (
          <Form>
            <Stack my="1em">
              {data.options && [
                { name: 'variation', label: '選擇圖表', options: data.options, type: 'select' },
                { name: 'size', label: '選擇尺寸', options: ['XS', 'S', 'M', 'L', 'XL'], type: 'select' }
              ].map(renderField)}
              {fields.map((d) => {
                if (data.noRequired && data.noRequired.includes(d.name)) {
                  return {...d, noRequired: true}
                } return d
              }).map(renderField)}
              <Box my="0.5em">
                <Text>贊助方案：<b>{data.adj}</b></Text>
                <Text>贊助金額：<b>NT$ {data.cost ? data.cost : money}</b></Text>
              </Box>
              <Button mt="1em" borderRadius="1.5em" isLoading={isSubmitting} type="submit">確認捐贈</Button>
              {checkoutError && <Text textAlign="center" color="red.400">交易錯誤</Text>}
              <Button
                mt="1em"
                borderRadius="1.5em"
                isLoading={isSubmitting}
                type="button"
                onClick={() => {
                  ReactGA.event({
                    category: 'Clicks',
                    action: '結帳-回上頁',
                  })
                  window.history.back()
                }}
              >回上頁</Button>
            </Stack>
          </Form>
        )}
      </Formik>
      <form ref={formRef} method="POST" />
    </Container>
  )
}

export default Checkout
