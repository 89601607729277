import React from 'react'

import Container from '../../components/Container'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Image from '../../components/Image'
import Text from '../../components/Text'
import BottomSnapButton from '../../components/BottomSnapButton'
import { responsive } from '../../components/ThemeProvider/theme';

import introDoor from '../intro-door-man.svg'
import clock from '../Introduction/clock.png'
import chat from '../Introduction/chat.png'
import FullpageVerticalCenter from '../../components/FullpageVerticalCenter'
import useResponsive from '../../contexts/mediaQuery/useResponsive'

const content = [
  {
    src: clock,
    text: '每題有20秒的時間可以回答'
  },
  {
    src: chat,
    text: '請憑直覺回答12個數據考驗'
  },
]

const Board = ({ title, children, ...props }) => (
  <Box
    mb={responsive('6', '8')}
    borderRadius="2em"
    fontSize={responsive('0.875em', '1.25em')}
    border="1px solid"
    {...props}
  >
    <Box
      bg="black"
      textAlign="center"
      letterSpacing="0.125em"
      color="white"
      borderRadius="2em 2em 0 0"
      py="0.5em"
    >{title}</Box>
    <Box px="1.5em" py={responsive('0.625em', '1rem')} borderRadius="2em 2em 0 0">
      {children}
    </Box>
  </Box>
)

const Introduction = () => {
  const { isMobile } = useResponsive()
  return (
    <>
      <FullpageVerticalCenter>
        <Container px={responsive(8, '15%')}>
          <Board
            position="relative"
            title="測驗會如何進行？"
          >
            <Box>
              {content.map(({ src, text }, k) => {
                return (
                <Flex
                  alignItems="center"
                  py={k && "0.75rem"}
                  key={k}
                >
                  <Box
                    mr="0.75em"
                    width="2.75em"
                  >
                    <Image src={src} />
                  </Box>
                  <Text>{text}</Text>
                </Flex>
              )})}
            </Box>
            <Box bg="white" width="3.75rem" position="absolute" bottom="-10%" right="0" transform="translateX(40%)">
              <Image src={introDoor} />
            </Box>
          </Board>
          {!isMobile && <BottomSnapButton to="/tpe/q/1">開始測驗</BottomSnapButton>}

        </Container>
      </FullpageVerticalCenter>
      {isMobile && <BottomSnapButton to="/tpe/q/1">開始測驗</BottomSnapButton>}
    </>
  )
}

export default Introduction
