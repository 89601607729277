import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import range from 'lodash/range'

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Box from './Box'
import Chevron from './Chevron'
import Flex from './Flex'
import Button from './Button'
import { responsive } from './ThemeProvider/theme';
import useResponsive from '../contexts/mediaQuery/useResponsive';

const StyleBox = styled(Box)`
  img,
  picture {
    display: block;
  }
  .slick-track {
    display: flex;
    align-items: stretch;
  }
  .slick-arrow {
    height: auto;
    font-size: 0.75em;
    z-index: 1;
    color: black;
    &::before {
      content: none;
    }
  }
  .slick-slide{
    float: none;
    height: auto;
    & > div {
      height: 100%;
      & > * {
        height: 100%;
      }
    }
  }
  .slick-prev {
    left: ${props => props.isMobile ? '-1.25em' : '-4.5em'};
  }
  .slick-next {
    right: ${props => props.isMobile ? '-1.25em' : '-4.5em'};
  }
`

const Carousel = ({
  children,
  nodot,
  width,
  flex,
  autoplay,
  arrows,
  slidesToShow,
  silderProps,
  beforeChange,
  ...props
}) => {
  const [activeItemIndex, setActiveIndex] = useState(0)
  const slickRef = useRef()
  useEffect(() => {
    if (slickRef.current) {
      slickRef.current.slickGoTo(activeItemIndex)
    }
  }, [slickRef, activeItemIndex])
  const { isMobile } = useResponsive()
  return (
    <StyleBox position="relative" isMobile={isMobile} width={width} flex={flex} {...props}>
      <Slider
        autoplay={autoplay}
        prevArrow={activeItemIndex === 0 ? <div /> : <Chevron.Left />}
        nextArrow={activeItemIndex === children.length - 1 ? <div /> : <Chevron.Right />}
        arrows={arrows}
        dots={false}
        ref={slickRef}
        autoplaySpeed={3500}
        speed={750}
        beforeChange={(from, to) => {
          if (typeof beforeChange === 'function') beforeChange(from, to)
          setActiveIndex(to)
        }}
        slidesToShow={slidesToShow}
        {...silderProps}
      >
        {children}
      </Slider>
      {!nodot && (
        <Flex
          position="absolute"
          bottom="1em"
          left="50%"
          transform="translateX(-50%)"
        >
          {range(children.length).map((i) => (
            <Box mx="0.25em" key={i}>
              <Button
                border="2px solid"
                borderColor="black"
                bg={i > activeItemIndex ? 'black' : 'bgYellow'}
                hoverBg="bgYellow"
                height="auto"
                minWidth="auto"
                py={responsive('0.125rem', '0.25rem')}
                px={responsive('0.125rem', '0.25rem')}
                borderRadius="full"
                active={i === activeItemIndex}
                onClick={() => setActiveIndex(i)}
              />
            </Box>
          ))}
        </Flex>
      )}
    </StyleBox>
  );
}

Carousel.defaultProps = {
  arrows: true,
}

export default Carousel;
