import React from 'react'
import { AspectRatioBox } from '@chakra-ui/core'
import {useSpring, animated} from 'react-spring'

import Box from '../../../../components/Box'
import Text from '../../../../components/Text'
import Image from '../../../../components/Image'

import theme, { responsive } from '../../../../components/ThemeProvider/theme'

import ruler from './ruler.svg'
import man from './man@2x.png'

import { ReactComponent as TwHappy } from './tw-happy.svg'
import { ReactComponent as TwNormal } from './tw-normal.svg'
import { ReactComponent as TwSad } from './tw-sad.svg'

const allTw = [TwSad, TwNormal, TwHappy]

const thres = [0, 50, 80]

const Distance = ({ totalScore, visible }) => {
  const spring = useSpring({
    score: visible ? totalScore : 100,
    config: {
      duration: 1000,
    },
  })

  return (
    <Box mt="1em" px="2em" pt="1em">
      {/* <Text fontSize="1.25em" fontWeight={500}>你的答錯率</Text> */}
      <AspectRatioBox ratio={450 / 300}>
        <Box>
          <Box.Absolute>
            <Box as="svg" width="full" viewBox="0 0 450 300" xmlns="http://www.w3.org/2000/svg">
              <text
                x="0"
                y="140"
                fill={theme.colors.primary}
                stroke="black"
                strokeWidth="2"
                fontSize="180"
                fontFamily={theme.fonts.body}
              >
                <animated.tspan>{spring.score.interpolate(val => Math.floor(100 - val))}</animated.tspan>
                %
              </text>
            </Box>
          </Box.Absolute>
          <Box.Absolute bottom="0" pb="5%" right="-22%" width="40%">
            <Box.Relative width="100%">
              {allTw.map((TW, i) => (
                <animated.div
                  style={{
                    position: 'absolute',
                    bottom: "0",
                    left: "0",
                    right: "0",
                    transform: 'translateX(-0.75em)',
                    opacity: spring.score.interpolate(val => (val >= thres[i]) * 1),
                  }}
                  key={i}
                >
                  <TW width="100%" />
                </animated.div>
              ))}
            </Box.Relative>
          </Box.Absolute>
          <Box.Absolute
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            overflow="hidden"
          >
            <animated.div
              style={{
                position: 'absolute',
                left: '100%',
                width: '100%',
                top: 0,
                bottom: 0,
                background: theme.colors.primary,
                transform: spring.score.interpolate(val => `translateX(-${100 - val}%)`),
              }}
            />
            <Image position="relative" src={ruler} />
          </Box.Absolute>
          <Box.Absolute
            left="0"
            right="0"
            bottom="0"
            pb="5%"
            overflowX="hidden"
            overflowY="visible"
          >
            <animated.div
              style={{
                marginLeft: '100%',
                width: '100%',
                transform: spring.score.interpolate(val => `translateX(-${100 - val}%)`),
              }}
            >
              <Box width="6%" transform="translateX(-100%)">
                <Image src={man} />
              </Box>
            </animated.div>
          </Box.Absolute>
        </Box>
      </AspectRatioBox>
    </Box>
  )
}

export default Distance
