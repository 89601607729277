import React from 'react'

import Button from '../../components/Button'

const FilterButton = (props) => (
  <Button
    width="100%"
    variant="outline"
    borderTop="none"
    borderLeft="none"
    borderRight="none"
    borderBottom="3px solid"
    borderBottomColor="black"
    fontFamily="body"
    px="4"
    justifyContent="space-between"
    fontWeight="normal"
    {...props}
  />
)

export default FilterButton
