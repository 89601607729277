import React, { forwardRef } from 'react';

import { Link as UiLink } from "@chakra-ui/core";
import { Link as RouterLink } from 'react-router-dom'

const Link = forwardRef(({ button, to, href, ...props }, ref) => {
  if (to) {
    return (
      <UiLink
        as={RouterLink}
        to={to}
        ref={ref}
        { ...props }
      />
    )
  }
  return (
    <UiLink
      target="_blank"
      href={href}
      ref={ref}
      rel="noreferrer noopener"
      { ...props }
    />
  );
});


Link.displayName = 'Link';

Link.defaultProps = {
  // _hover: {
  //   textDecoration: 'none',
  // }
}

Link.Underline = props => (
  <Link textDecoration="underline" {...props} />
)

export default Link
