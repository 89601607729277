import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { useFirebaseConnect } from 'react-redux-firebase'

import useUser from './useUser'

const useUserAnswer = () => {
  const { hasUser, answerKey } = useUser()
  useFirebaseConnect([hasUser && answerKey ? `answers/${answerKey}` : 'null'])
  const answer = useSelector(state => get(state.firebase.data, ['answers', hasUser && answerKey ? answerKey : '']))
  return answer ? { ...answer, key: answerKey } : answer
}

export default useUserAnswer
